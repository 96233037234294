import {
  Button,
  Card,
  CardRow,
  Checkbox,
  EmptyState,
  IconFilter,
  IconUserSearch,
  InlineNotification,
  Label,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableRowHead,
  Text,
  TextShortener,
  ToolTip,
  LoaderDots,
} from '@userclouds/ui-component-lib';

import { makeCleanPageLink } from '../AppNavigation';
import { AppDispatch } from '../store';
import PaginatedResult from '../models/PaginatedResult';
import { SelectedTenant } from '../models/Tenant';
import Accessor, {
  ACCESSOR_COLUMNS,
  ACCESSOR_PREFIX,
} from '../models/Accessor';

import {
  bulkDeleteMutatorsOrAccessors,
  deleteSingleAccessor,
} from '../thunks/userstore';
import {
  changeAccessorSearchFilter,
  toggleAccessorListIncludeAutogenerated,
  toggleAccessorForDelete,
} from '../actions/accessors';
import Link from '../controls/Link';
import Pagination from '../controls/Pagination';
import PageCommon from './PageCommon.module.css';
import { Filter } from '../models/authz/SearchFilters';
import Search from '../controls/Search';
import Styles from './AccessorList.module.css';
import DeleteWithConfirmationButton from '../controls/DeleteWithConfirmationButton';
import type { CountMetric } from '../models/Metrics';

export const AccessorTable = ({
  selectedTenant,
  accessors,
  isFetching,
  fetchError,
  deleteQueue,
  saveSuccess,
  saveErrors,
  query,
}: {
  selectedTenant: SelectedTenant | undefined;
  accessors: PaginatedResult<Accessor> | undefined;
  isFetching: boolean;
  fetchError: string;
  deleteQueue: Record<string, Accessor>;
  saveSuccess: string;
  saveErrors: string[];
  query: URLSearchParams;
}) => {
  const cleanQuery = makeCleanPageLink(query);

  return (
    <>
      {accessors ? (
        accessors.data && accessors.data.length ? (
          <>
            {saveSuccess && (
              <InlineNotification theme="success">
                {saveSuccess}
              </InlineNotification>
            )}
            {!!saveErrors.length && (
              <InlineNotification theme="alert">
                {saveErrors.length > 1
                  ? `Error deleting ${saveErrors.length} accessors`
                  : saveErrors[0]}
              </InlineNotification>
            )}
            <Table spacing="packed" className={Styles.accessorstable}>
              <TableHead floating>
                <TableRow>
                  <TableRowHead key="accessor_name">Name</TableRowHead>
                  <TableRowHead key="accessor_id">ID</TableRowHead>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessors.data.map((accessor) => (
                  <TableRow
                    key={accessor.id}
                    className={
                      (deleteQueue[accessor.id]
                        ? PageCommon.queuedfordelete
                        : '') +
                      ' ' +
                      PageCommon.listviewtablerow
                    }
                    isExtensible
                    columns={3}
                    expandedContent={
                      <div className={PageCommon.accessorexpandedtable}>
                        <Label className={PageCommon.expandedtableitem}>
                          All Columns Read by This Accessor
                          <br />
                          <p className={PageCommon.expandedtableitem}>
                            {accessor.columns.map((col) => col.name).join(', ')}{' '}
                          </p>
                        </Label>
                        <Label className={PageCommon.expandedtableitem}>
                          Version
                          <br />
                          <>{accessor.version}</>
                        </Label>
                        <Label className={PageCommon.expandedtableitem}>
                          Access Policy
                          <br />
                          <Link
                            href={`/accesspolicies/${accessor.access_policy.id}/latest${cleanQuery}`}
                            title="View details for this access policy"
                          >
                            {accessor.access_policy.name}
                          </Link>
                        </Label>
                      </div>
                    }
                  >
                    <TableCell>
                      <Link
                        href={`/accessors/${accessor.id}/${accessor.version}${cleanQuery}`}
                        title="View details for this accessor"
                      >
                        {accessor.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <TextShortener text={accessor.id} length={6} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <CardRow>
            <EmptyState
              title="No accessors"
              image={<IconUserSearch size="large" />}
            >
              {selectedTenant?.is_admin && (
                <Button theme="secondary">
                  <Link
                    href={`/accessors/create${cleanQuery}`}
                    applyStyles={false}
                  >
                    + Add accessor
                  </Link>
                </Button>
              )}
            </EmptyState>
          </CardRow>
        )
      ) : isFetching ? (
        <Text>Fetching tenant accessors...</Text>
      ) : (
        <InlineNotification theme="alert">
          {fetchError || 'Something went wrong'}
        </InlineNotification>
      )}
    </>
  );
};

const AccessorList = ({
  selectedTenant,
  accessors,
  accessorMetrics,
  isFetching,
  fetchError,
  isFetchingAccessorMetrics,
  deleteQueue,
  includeAutogenerated,
  saveSuccess,
  saveErrors,
  query,
  accessorSearchFilter,
  readOnly = false,
  withMetrics,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  accessors: PaginatedResult<Accessor> | undefined;
  accessorMetrics?: CountMetric[] | undefined;
  isFetching: boolean;
  fetchError: string;
  isFetchingAccessorMetrics?: boolean;
  deleteQueue: Record<string, Accessor>;
  includeAutogenerated: boolean;
  saveSuccess: string;
  saveErrors: string[];
  query: URLSearchParams;
  accessorSearchFilter: Filter;
  readOnly?: boolean;
  withMetrics?: boolean;
  dispatch: AppDispatch;
}) => {
  const cleanQuery = makeCleanPageLink(query);
  const objectsToDelete = Object.keys(deleteQueue).length;

  const deleteMessage = `Are you sure you want to delete ${objectsToDelete} accessor${
    objectsToDelete > 1 ? 's' : ''
  }? This action is irreversible.`;
  return (
    <>
      {!readOnly && (
        <div className={PageCommon.listviewtablecontrols}>
          <div>
            <IconFilter />
          </div>
          <Search
            columns={ACCESSOR_COLUMNS}
            changeSearchFilter={(filter: Filter) => {
              dispatch(changeAccessorSearchFilter(filter));
            }}
            prefix={ACCESSOR_PREFIX}
            searchFilter={accessorSearchFilter}
          />
          <Checkbox
            id="include_autogenerated"
            checked={includeAutogenerated}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(
                toggleAccessorListIncludeAutogenerated(e.currentTarget.checked)
              );
            }}
          >
            Include autogenerated
          </Checkbox>
          <div className={PageCommon.listviewtablecontrolsToolTip}>
            <ToolTip>
              <>
                {
                  'Accessors are configurable APIs that allow a client to retrieve data from the user store. '
                }
                <a
                  href="https://docs.userclouds.com/docs/accessors-read-apis"
                  title="UserClouds documentation for accessors (read APIs)"
                  target="new"
                  className={PageCommon.link}
                >
                  Learn more here.
                </a>
              </>
            </ToolTip>
          </div>
          {selectedTenant?.is_admin && (
            <Button
              theme="primary"
              size="small"
              className={PageCommon.listviewtablecontrolsButton}
            >
              <Link href={`/accessors/create${cleanQuery}`} applyStyles={false}>
                Create Accessor
              </Link>
            </Button>
          )}
        </div>
      )}
      <Card
        id="userstoreAccessors"
        lockedMessage={
          !selectedTenant?.is_admin ? 'You do not have edit access' : ''
        }
        listview
      >
        {accessors ? (
          accessors.data && accessors.data.length ? (
            <>
              {saveSuccess && (
                <InlineNotification theme="success">
                  {saveSuccess}
                </InlineNotification>
              )}
              {!!saveErrors.length && (
                <InlineNotification theme="alert">
                  {saveErrors.length > 1
                    ? `Error deleting ${saveErrors.length} accessors`
                    : saveErrors[0]}
                </InlineNotification>
              )}

              <div className={PageCommon.listviewpaginationcontrols}>
                <div className={PageCommon.listviewpaginationcontrolsdelete}>
                  <DeleteWithConfirmationButton
                    disabled={objectsToDelete < 1}
                    id="deleteAccessorsButton"
                    message={deleteMessage}
                    onConfirmDelete={() => {
                      dispatch(
                        bulkDeleteMutatorsOrAccessors(
                          selectedTenant?.id || '',
                          deleteQueue,
                          'accessor'
                        )
                      );
                    }}
                    title="Delete Accessors"
                  />
                </div>
                <Pagination
                  prev={accessors?.prev}
                  next={accessors?.next}
                  isLoading={isFetching}
                  prefix={ACCESSOR_PREFIX}
                />
              </div>

              <Table
                spacing="nowrap"
                id="accessors"
                className={Styles.accessorlisttable}
              >
                <TableHead floating>
                  <TableRow>
                    <TableRowHead className={Styles.checkboxCol}>
                      <Checkbox
                        checked={objectsToDelete === accessors.data.length}
                        onChange={() => {
                          const shouldMarkForDelete =
                            !deleteQueue[accessors.data[0].id];
                          accessors.data.forEach((o) => {
                            if (shouldMarkForDelete && !deleteQueue[o.id]) {
                              dispatch(toggleAccessorForDelete(o));
                            } else if (
                              !shouldMarkForDelete &&
                              deleteQueue[o.id]
                            ) {
                              dispatch(toggleAccessorForDelete(o));
                            }
                          });
                        }}
                      />
                    </TableRowHead>

                    <TableRowHead
                      key="accessor_name"
                      className={Styles.nameCol}
                    >
                      Name
                    </TableRowHead>
                    <TableRowHead
                      key="accessor_tables"
                      className={Styles.tablesCol}
                    >
                      Tables
                    </TableRowHead>
                    <TableRowHead
                      key="accessor_columns"
                      className={Styles.columnsCol}
                    >
                      Columns
                    </TableRowHead>
                    <TableRowHead
                      key="accessor_where_clause"
                      className={Styles.whereCol}
                    >
                      Where Clause
                    </TableRowHead>
                    {withMetrics && (
                      <TableRowHead
                        key="accessor_executions"
                        className={Styles.executionsCol}
                      >
                        Executions (30 days)
                      </TableRowHead>
                    )}
                    <TableRowHead
                      key="accessor_version"
                      className={Styles.versionCol}
                    >
                      Version
                    </TableRowHead>
                    <TableRowHead key="accessor_id" className={Styles.idCol}>
                      ID
                    </TableRowHead>
                    <TableRowHead
                      key="delete_accessor"
                      className={Styles.deleteCol}
                    />
                    <TableRowHead className={Styles.chevronCol} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accessors.data.map((accessor) => (
                    <TableRow
                      key={accessor.id}
                      isExtensible
                      className={
                        (deleteQueue[accessor.id]
                          ? PageCommon.queuedfordelete
                          : '') +
                        ' ' +
                        PageCommon.listviewtablerow
                      }
                    >
                      <TableCell>
                        <Checkbox
                          id={'delete' + accessor.id}
                          name="delete object"
                          checked={deleteQueue[accessor.id]}
                          onChange={() => {
                            dispatch(toggleAccessorForDelete(accessor));
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <Link
                          href={`/accessors/${accessor.id}/${accessor.version}${cleanQuery}`}
                          title="View details for this accessor"
                        >
                          {accessor.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {accessor.columns
                          .map((col) => col.table)
                          .filter(
                            (value, index, self) =>
                              self.indexOf(value) === index
                          )
                          .join(', ')}
                      </TableCell>
                      <TableCell>
                        {accessor.columns.map((col) => col.name).join(', ')}
                      </TableCell>
                      <TableCell>
                        {accessor.selector_config?.where_clause}
                      </TableCell>
                      {withMetrics && (
                        <TableCell>
                          {isFetchingAccessorMetrics ? (
                            <LoaderDots size="small" />
                          ) : (
                            (accessorMetrics?.find(
                              (metric) => metric.id === accessor.id
                            )?.count ?? '—')
                          )}
                        </TableCell>
                      )}
                      <TableCell>{accessor.version}</TableCell>
                      <TableCell>
                        <TextShortener text={accessor.id} length={6} />
                      </TableCell>
                      <TableCell className={PageCommon.listviewtabledeletecell}>
                        <DeleteWithConfirmationButton
                          id="deleteAccessorButton"
                          message="Are you sure you want to delete this accessor? This action is irreversible."
                          onConfirmDelete={() => {
                            if (selectedTenant) {
                              dispatch(
                                deleteSingleAccessor(
                                  selectedTenant.id,
                                  accessor.id
                                )
                              );
                            }
                          }}
                          title="Delete Accessor"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <CardRow>
              <EmptyState
                title="No accessors"
                image={<IconUserSearch size="large" />}
              >
                {selectedTenant?.is_admin && (
                  <Button theme="secondary">
                    <Link
                      href={`/accessors/create${cleanQuery}`}
                      applyStyles={false}
                    >
                      + Add accessor
                    </Link>
                  </Button>
                )}
              </EmptyState>
            </CardRow>
          )
        ) : isFetching ? (
          <Text>Fetching tenant accessors...</Text>
        ) : (
          <InlineNotification theme="alert">
            {fetchError || 'Something went wrong'}
          </InlineNotification>
        )}
      </Card>
    </>
  );
};

export default AccessorList;
