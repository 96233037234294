import { IconTypes, getSize } from '../iconHelpers';

function IconFileCode({ className, size = 'medium' }: IconTypes): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={getSize(size)}
      height={getSize(size)}
      fill="currentColor"
      className={className}
    >
      <title>File with code</title>
      <path d="M16 2L21 7V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918C3 2.44405 3.44495 2 3.9934 2H16ZM17.6569 12L14.1213 8.46447L12.7071 9.87868L14.8284 12L12.7071 14.1213L14.1213 15.5355L17.6569 12ZM6.34315 12L9.87868 15.5355L11.2929 14.1213L9.17157 12L11.2929 9.87868L9.87868 8.46447L6.34315 12Z" />
    </svg>
  );
}

export default IconFileCode;
