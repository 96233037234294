import { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState, AppDispatch } from '../store';
import { fetchAccessors } from '../thunks/accessors';

import AccessorList from './AccessorList';

const ConnectedAccessorList = connect((state: RootState) => ({
  selectedTenant: state.selectedTenant,
  accessors: state.accessors,
  accessorMetrics: state.accessorMetrics,
  isFetching: state.fetchingAccessors,
  fetchError: state.fetchAccessorsError,
  isFetchingAccessorMetrics: state.fetchingAccessorMetrics,
  deleteQueue: state.accessorsToDelete,
  includeAutogenerated: state.accessorListIncludeAutogenerated,
  saveSuccess: state.bulkUpdateAccessorsSuccess,
  saveErrors: state.bulkUpdateAccessorsErrors,
  query: state.query,
  accessorSearchFilter: state.accessorSearchFilter,
}))(AccessorList);

const AccessorsPage = ({
  selectedTenantID,
  query,
  includeAutogenerated,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  query: URLSearchParams;
  includeAutogenerated: boolean;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenantID) {
      const withMetrics = true;
      dispatch(
        fetchAccessors(
          selectedTenantID,
          query,
          includeAutogenerated,
          withMetrics
        )
      );
    }
  }, [selectedTenantID, query, dispatch, includeAutogenerated]);

  return <ConnectedAccessorList withMetrics />;
};

export default connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  location: state.location,
  query: state.query,
  includeAutogenerated: state.accessorListIncludeAutogenerated,
  featureFlags: state.featureFlags,
}))(AccessorsPage);
