import ChartQuery from './ChartQuery';
import StatusPageStyles from '../pages/StatusPage.module.css';

export interface ChartRenderingDetails {
  title: string;
  className: string;
  divId: string;
  labels: string[];
}
export type AllChartsMetadata = {
  [key: string]: {
    service: string;
    queries: ChartQuery[];
    charts: ChartRenderingDetails[];
  };
};

const serviceChartMetadata = {
  plex: {
    service: 'plex',
    queries: [
      new ChartQuery([99, 400, 304, 307, 200]),
      new ChartQuery([1002, 2240, 1010, 1003, 1004, 1005, 1006]),
      new ChartQuery([1035, 1036, 1038]),
      new ChartQuery([1045, 1055]),
    ],
    charts: [
      {
        title: 'HTTP Return Codes For All Requests',
        className: StatusPageStyles.containerHttp,
        divId: 'containerHttp',
        labels: ['Total Requests'],
      },
      {
        title: 'Logins and Logouts',
        className: StatusPageStyles.containerRequests,
        divId: 'containerRequests',
        labels: [
          'U/P Login',
          'Social Login',
          'Logout',
          'Session Error',
          'Provider Error',
          'Input Error',
          'MFA Error',
        ],
      },
      {
        title: 'Account Creation',
        className: StatusPageStyles.containerAccCreation,
        divId: 'containerAccCreation',
        labels: ['Create Account Calls', 'Bad Input', 'Provider Error'],
      },
      {
        title: 'Authorize and Token Generation Calls',
        className: StatusPageStyles.containerAuthAndToken,
        divId: 'containerAuthAndToken',
        labels: ['Authorize Calls', 'Token Calls'],
      },
    ],
  },
  authz: {
    service: 'authz',
    queries: [
      new ChartQuery([99, 400, 304, 307, 200]),
      new ChartQuery([1900, 1930, 1970, 2010]),
      new ChartQuery([1890, 1910, 2450, 1920, 1950, 1960, 1990, 2000]),
      new ChartQuery([1980, 2020]),
    ],
    charts: [
      {
        title: 'HTTP Return Codes For All Requests',
        className: StatusPageStyles.containerHttp,
        divId: 'containerHttp',
        labels: ['Total Requests'],
      },
      {
        title: 'Creates',
        className: StatusPageStyles.containerRequests,
        divId: 'containerRequests',
        labels: ['Object Type', 'Edge Type', 'Objects', 'Edges'],
      },
      {
        title: 'Queries',
        className: StatusPageStyles.containerAccCreation,
        divId: 'containerAccCreation',
        labels: [
          'List Object Types',
          'List Edge Types',
          'Get Object Type',
          'Get Edge Type',
          'List Objects',
          'Get Object',
          'List Edges',
          'Get Edge',
        ],
      },
      {
        title: 'Deletes',
        className: StatusPageStyles.containerAuthAndToken,
        divId: 'containerAuthAndToken',
        labels: ['Object', 'Edge'],
      },
    ],
  },
  idp: {
    service: 'idp',
    queries: [
      new ChartQuery([99, 400, 304, 307, 200]),
      new ChartQuery([1620]),
      new ChartQuery([1650, 1680]),
      new ChartQuery([1640, 1630, 2110, 2100]),
    ],
    charts: [
      {
        title: 'HTTP Return Codes For All Requests',
        className: StatusPageStyles.containerHttp,
        divId: 'containerHttp',
        labels: ['Total Requests'],
      },
      {
        title: 'Logins',
        className: StatusPageStyles.containerRequests,
        divId: 'containerRequests',
        labels: ['Logins Password'],
      },
      {
        title: 'Account Creation',
        className: StatusPageStyles.containerAccCreation,
        divId: 'containerAccCreation',
        labels: ['Create Account Calls', 'Migrate Account Calls'],
      },
      {
        title: 'Queries',
        className: StatusPageStyles.containerAuthAndToken,
        divId: 'containerAuthAndToken',
        labels: ['Get UserInfo', 'Update User', 'Get User', 'Get Users'],
      },
    ],
  },
  console: {
    service: 'console',
    queries: [
      new ChartQuery([99, 400, 304, 307, 200]),
      new ChartQuery([1700, 1710]),
      new ChartQuery([1750, 1760, 1860, 1870]),
      new ChartQuery([1800, 1810]),
    ],
    charts: [
      {
        title: 'HTTP Return Codes For All Requests',
        className: StatusPageStyles.containerHttp,
        divId: 'containerHttp',
        labels: ['Total Requests'],
      },
      {
        title: 'Console Activity',
        className: StatusPageStyles.containerRequests,
        divId: 'containerRequests',
        labels: ['Login', 'Logout'],
      },
      {
        title: 'Companies',
        className: StatusPageStyles.containerAccCreation,
        divId: 'containerAccCreation',
        labels: ['Creates', 'Deletes', 'Add Members', 'Remove Members'],
      },
      {
        title: 'Tenants',
        className: StatusPageStyles.containerAuthAndToken,
        divId: 'containerAuthAndToken',
        labels: ['Create', 'Delete'],
      },
    ],
  },
  tokenizer: {
    service: 'tokenizer',
    queries: [
      new ChartQuery([99, 400, 304, 307, 200]),
      new ChartQuery([2610, 2660, 2600, 2650, 2620, 2630, 2670]),
      new ChartQuery([2570, 2580]),
      new ChartQuery([2830, 2820, 2810]),
    ],
    charts: [
      {
        title: 'HTTP Return Codes For All Requests',
        className: StatusPageStyles.containerHttp,
        divId: 'containerHttp',
        labels: ['Total Requests'],
      },
      {
        title: 'Policies',
        className: StatusPageStyles.containerRequests,
        divId: 'containerRequests',
        labels: [
          'Access Policy Create',
          'Transformer Create',
          'Access Policy Get',
          'Transformer Get',
          'Access Policy Update',
          'Access Policy Delete',
          'Transformer Delete',
        ],
      },
      {
        title: 'Tokens',
        className: StatusPageStyles.containerAccCreation,
        divId: 'containerAccCreation',
        labels: ['Create', 'Delete'],
      },
      {
        title: 'Token Resolution',
        className: StatusPageStyles.containerAuthAndToken,
        divId: 'containerAuthAndToken',
        labels: ['Resolution', 'Lookup', 'Inspect'],
      },
    ],
  },
};

export default serviceChartMetadata;
