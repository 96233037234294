import { IconTypes, getSize } from '../iconHelpers';

function IconAccessPermissions({
  className,
  size = 'medium',
}: IconTypes): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={getSize(size)}
      height={getSize(size)}
      className={className}
      fill="currentColor"
    >
      <title>Access Permissions</title>
      <path
        d="M7.5 0L14.3475 1.52167C14.5326 1.5628 14.698 1.6658 14.8167 1.81367C14.9353 1.96154 15 2.14543 15 2.335V10.6575C14.9999 11.4806 14.7967 12.291 14.4082 13.0167C14.0198 13.7424 13.4582 14.3609 12.7733 14.8175L7.5 18.3333L2.22667 14.8175C1.54189 14.361 0.980384 13.7426 0.591957 13.017C0.20353 12.2915 0.000196964 11.4813 0 10.6583V2.335C3.25315e-05 2.14543 0.0646983 1.96154 0.183325 1.81367C0.301951 1.6658 0.467447 1.5628 0.6525 1.52167L7.5 0ZM7.5 5C7.13312 5.00002 6.7765 5.12109 6.48544 5.34443C6.19439 5.56778 5.98515 5.88092 5.8902 6.2353C5.79524 6.58967 5.81987 6.96548 5.96026 7.30443C6.10065 7.64338 6.34895 7.92655 6.66667 8.11V11.6667H8.33333L8.33417 8.11C8.65194 7.92651 8.90028 7.64328 9.04066 7.30424C9.18103 6.9652 9.20559 6.58931 9.11053 6.23489C9.01546 5.88047 8.80609 5.56733 8.51489 5.34405C8.22369 5.12077 7.86695 4.99983 7.5 5Z"
        fill="#283354"
      />{' '}
    </svg>
  );
}

export default IconAccessPermissions;
