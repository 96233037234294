/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { JSONValue } from '@userclouds/sharedui';

/*
TODO - This is the JS type Google Charts expects for the charts. It doesn't
have be the on the wire protocol from the query method, that should be changed to something more
reasonable.

type (
	//ColsType is Google Chart standard Cols type
	ColsType struct {
		ID      string `json:"id"`
		Label   string `json:"label"`
		Pattern string `json:"pattern"`
		Type    string `json:"type"`
	}
	//RowType is Google Chart standard Rows type
	RowType struct {
		C []RowCType `json:"c"`
	}
	//RowCType is Google Chart standard Rows.C type
	RowCType struct {
		V interface{} `json:"v"`
		F interface{} `json:"f"`
	}
	//Chart is Google Chart standard type
	Chart struct {
		Cols []ColsType `json:"cols"`
		Rows []RowType  `json:"rows"`
	}
)
*/

export type ChartDataSerialized = {
  cols: {
    id: string;
    label: string;
    pattern: string;
    type: string;
  }[];
  rows: {
    c: {
      v: any;
      f: any;
    }[];
  }[];
};

class ChartData {
  data: ChartDataSerialized[];

  constructor(data: ChartDataSerialized[]) {
    this.data = data;
  }

  getGraphData(i: number) {
    return this.data[i];
  }

  static fromJSON(json: JSONValue): ChartData {
    const chartDataJSON = json as ChartDataSerialized[];
    return new ChartData(chartDataJSON ? [...chartDataJSON] : []);
  }
}

export default ChartData;
