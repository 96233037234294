import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Card,
  CardRow,
  Checkbox,
  Dialog,
  DialogBody,
  EmptyState,
  GlobalStyles,
  Heading,
  IconFocus3Line,
  InlineNotification,
  InputReadOnly,
  Label,
  LoaderDots,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowHead,
  Text,
  TextInput,
  TextShortener,
  ToolTip,
} from '@userclouds/ui-component-lib';

import { RootState, AppDispatch } from '../store';
import { NilUuid } from '../models/Uuids';
import PaginatedResult from '../models/PaginatedResult';
import { SelectedTenant } from '../models/Tenant';
import {
  Column,
  ColumnIndexType,
  blankColumn,
  uniqueIDsAvailable,
  partialUpdatesAvailable,
  immutableAvailable,
  uniqueValuesAvailable,
  getSelectedDataType,
} from '../models/TenantUserStoreConfig';
import Purpose from '../models/Purpose';
import { DataType } from '../models/DataType';
import Transformer, {
  TokenizingTransformerTypes,
  DEFAULT_TRANSFORMER_NAME,
  findByName,
} from '../models/Transformer';
import AccessPolicy, {
  blankPolicy,
  blankPolicyTemplate,
  AccessPolicyTemplate,
} from '../models/AccessPolicy';
import {
  PurposeRetentionDuration,
  DurationUnit,
  DurationType,
  displayDuration,
} from '../models/ColumnRetentionDurations';
import {
  columnToAccessorColumn,
  transformerIsValidForAccessorColumn,
} from '../models/Accessor';
import {
  getTokenAccessPolicySuccess,
  modifyAccessPolicy,
  modifyTokenAccessPolicy,
} from '../actions/tokenizer';
import {
  fetchUserStoreColumnSuccess,
  modifyUserStoreColumn,
  modifyUserStoreColumnDefaultTransformer,
  toggleColumnEditMode,
  toggleColumnPurposesEditMode,
  modifyRetentionDuration,
  changeSelectedColumn,
} from '../actions/userstore';
import { toggleAccessorListIncludeAutogenerated } from '../actions/accessors';
import {
  fetchAccessPolicy,
  fetchTokenAccessPolicy,
  fetchTransformers,
  createAccessPolicyTemplateForAccessPolicy,
  fetchAllAccessPolicies,
} from '../thunks/tokenizer';
import {
  createColumn,
  fetchColumn,
  updateColumn,
  fetchRetentionDurationsForColumn,
  updateRetentionDurationsForColumn,
  fetchDataTypes,
} from '../thunks/userstore';
import { fetchAccessorsForColumn } from '../thunks/accessors';
import { fetchPurposes } from '../thunks/purposes';
import { makeCleanPageLink } from '../AppNavigation';
import Link from '../controls/Link';
import { MAX_LIMIT } from '../controls/PaginationHelper';
import { redirect } from '../routing';
import PolicyComposer, { ConnectedPolicyChooserDialog } from './PolicyComposer';
import PolicyTemplateForm from './PolicyTemplateForm';
import { truncateWithEllipsis } from '../util/string';
import { AccessorTable } from './AccessorList';
import PageCommon from './PageCommon.module.css';
import styles from './ColumnPage.module.css';

const ColumnDetails = ({
  allAccessPolicies,
  dataTypes,
  editMode,
  fetchError,
  fetchingAllAccessPolicies,
  fetchingTransformers,
  includeAutogeneratedAccessors,
  isCreatePage,
  modifiedAccessPolicy,
  modifiedColumn,
  modifiedTokenAccessPolicy,
  newTemplate,
  policyTemplateDialogIsOpen,
  saveError,
  saveSuccess,
  selectedColumn,
  selectedTenant,
  selectedTenantID,
  transformers,
  query,
  dispatch,
}: {
  allAccessPolicies: PaginatedResult<AccessPolicy> | undefined;
  dataTypes: DataType[];
  editMode: boolean;
  fetchError: string;
  fetchingAllAccessPolicies: boolean;
  fetchingTransformers: boolean;
  includeAutogeneratedAccessors: boolean;
  isCreatePage: boolean;
  modifiedAccessPolicy: AccessPolicy | undefined;
  modifiedColumn: Column | undefined;
  modifiedTokenAccessPolicy: AccessPolicy | undefined;
  newTemplate: AccessPolicyTemplate | undefined;
  policyTemplateDialogIsOpen: boolean;
  saveError: string;
  saveSuccess: string;
  selectedColumn: Column | undefined;
  selectedTenant: SelectedTenant | undefined;
  selectedTenantID: string | undefined;
  transformers: PaginatedResult<Transformer> | undefined;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (isCreatePage) {
      const selectedDataType = getSelectedDataType('string', dataTypes);
      dispatch(
        modifyUserStoreColumn({
          data_type: {
            id: selectedDataType?.id,
            name: selectedDataType?.name,
          },
          constraints: {},
        })
      );
    }
  }, [dataTypes, dispatch, isCreatePage]);

  useEffect(() => {
    if (selectedTenantID) {
      dispatch(fetchAllAccessPolicies(selectedTenantID));
    }
  }, [selectedTenantID, dispatch]);

  if (!selectedTenant) {
    return (
      <InlineNotification theme="alert">
        Unable to find tenant.
      </InlineNotification>
    );
  }

  if (fetchError) {
    return <InlineNotification theme="alert">{fetchError}</InlineNotification>;
  }

  if (!selectedColumn || !modifiedColumn) {
    return <Text>Loading ...</Text>;
  }

  const cleanQuery = makeCleanPageLink(query);
  const dialog: HTMLDialogElement | null = document.getElementById(
    'createPolicyTemplateDialog'
  ) as HTMLDialogElement;

  const showTokenAccessPolicy =
    transformers?.data &&
    TokenizingTransformerTypes.includes(
      transformers.data.find(
        (transformer) =>
          transformer.id === modifiedColumn.default_transformer?.id
      )?.transform_type || ''
    );

  let defaultTranformerId: string | undefined =
    modifiedColumn?.default_transformer?.id;

  if (
    !defaultTranformerId ||
    modifiedColumn?.default_transformer?.id === NilUuid
  ) {
    const defaultTranformer = findByName(
      transformers?.data,
      DEFAULT_TRANSFORMER_NAME
    );

    defaultTranformerId = defaultTranformer?.id;
  }

  return (
    <Card
      id="basicDetails"
      lockedMessage={
        selectedColumn.is_system ? 'System column details cannot be edited' : ''
      }
      detailview
    >
      <CardRow
        title="Basic Details"
        tooltip={
          <>
            {
              'Object types, like users and groups, define the structure of your authorization model. All objects have exactly one type. '
            }
            <a
              href="https://docs.userclouds.com/docs/key-concepts-1#objects"
              title="UserClouds documentation for key concepts in authorization"
              target="new"
              className={PageCommon.link}
            >
              Learn more here.
            </a>
          </>
        }
        collapsible
      >
        {isCreatePage ? (
          modifiedColumn && (
            <>
              <div className={PageCommon.carddetailsrow}>
                <Label>
                  Name
                  <br />
                  <TextInput
                    id="name"
                    name="name"
                    value={modifiedColumn.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(modifyUserStoreColumn({ name: e.target.value }));
                    }}
                  />
                </Label>

                <Label>
                  ID
                  <br />
                  <InputReadOnly>{selectedColumn.id}</InputReadOnly>
                </Label>

                <Label>
                  Column type
                  <br />
                  <Select
                    name="field_type"
                    value={modifiedColumn.data_type.name}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const selectedDataType = getSelectedDataType(
                        e.target.value,
                        dataTypes
                      );
                      if (selectedDataType) {
                        dispatch(
                          modifyUserStoreColumn({
                            data_type: {
                              id: selectedDataType.id,
                              name: selectedDataType.name,
                            },
                          })
                        );
                      }
                    }}
                    required
                  >
                    {dataTypes.map((type) => (
                      <option value={type.name} key={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </Select>
                </Label>

                <Label>
                  Table
                  <br />
                  <Text>{modifiedColumn.table}</Text>
                </Label>
              </div>
              <div className={styles.gridStyle}>
                <Label>
                  Array?
                  <br />
                  <Checkbox
                    id="is_array"
                    name="is_array"
                    checked={modifiedColumn.is_array}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(
                        modifyUserStoreColumn({
                          is_array: e.target.checked,
                        })
                      );
                    }}
                  />
                </Label>

                <Label>
                  Unique Values For User
                  <br />
                  <Checkbox
                    id="unique_values_for_user"
                    name="unique_values_for_user"
                    checked={modifiedColumn.constraints.unique_required}
                    onChange={() => {
                      dispatch(
                        modifyUserStoreColumn({
                          constraints: Object.assign(
                            modifiedColumn.constraints,
                            {
                              unique_required:
                                !modifiedColumn.constraints.unique_required,
                            }
                          ),
                        })
                      );
                    }}
                    disabled={!uniqueValuesAvailable(modifiedColumn, dataTypes)}
                  />
                </Label>

                <Label>
                  Unique IDs For User
                  <br />
                  <Checkbox
                    id="unique_ids_for_user"
                    name="unique_ids_for_user"
                    checked={modifiedColumn.constraints.unique_id_required}
                    onChange={() => {
                      dispatch(
                        modifyUserStoreColumn({
                          constraints: Object.assign(
                            modifiedColumn.constraints,
                            {
                              unique_id_required:
                                !modifiedColumn.constraints.unique_id_required,
                            }
                          ),
                        })
                      );
                    }}
                    disabled={!uniqueIDsAvailable(modifiedColumn, dataTypes)}
                  />
                </Label>

                <Label>
                  Partial Updates
                  <br />
                  <Checkbox
                    id="allow_partial_updates"
                    name="allow_partial_updates"
                    checked={modifiedColumn.constraints.partial_updates}
                    onChange={() => {
                      dispatch(
                        modifyUserStoreColumn({
                          constraints: Object.assign(
                            modifiedColumn.constraints,
                            {
                              partial_updates:
                                !modifiedColumn.constraints.partial_updates,
                            }
                          ),
                        })
                      );
                    }}
                    disabled={!partialUpdatesAvailable(modifiedColumn)}
                  />
                </Label>

                <Label>
                  Immutable
                  <br />
                  <Checkbox
                    id="immutable"
                    name="immutable"
                    checked={modifiedColumn.constraints.immutable_required}
                    onChange={() => {
                      dispatch(
                        modifyUserStoreColumn({
                          constraints: Object.assign(
                            modifiedColumn.constraints,
                            {
                              immutable_required:
                                !modifiedColumn.constraints.immutable_required,
                            }
                          ),
                        })
                      );
                    }}
                    disabled={!immutableAvailable(modifiedColumn, dataTypes)}
                  />
                </Label>

                <Label>
                  Unique within Tenant
                  <Checkbox
                    name="unique"
                    id="unique"
                    checked={
                      modifiedColumn.index_type === ColumnIndexType.Unique
                    }
                    onChange={() => {
                      dispatch(
                        modifyUserStoreColumn({
                          index_type:
                            modifiedColumn.index_type ===
                            ColumnIndexType.Indexed
                              ? ColumnIndexType.Unique
                              : ColumnIndexType.Indexed,
                        })
                      );
                    }}
                  />
                </Label>

                <Label>
                  Search Indexed
                  <br />
                  <Checkbox
                    id="searched_index"
                    name="searched_index"
                    checked={modifiedColumn.search_indexed}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(
                        modifyUserStoreColumn({
                          search_indexed: e.target.checked,
                        })
                      );
                    }}
                  />
                </Label>
              </div>
            </>
          )
        ) : editMode ? (
          <>
            <div className={PageCommon.carddetailsrow}>
              <Label>
                Name
                <br />
                <TextInput
                  id="name"
                  name="name"
                  value={modifiedColumn.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(modifyUserStoreColumn({ name: e.target.value }));
                  }}
                />
              </Label>

              <Label>
                ID
                <br />
                <TextShortener text={selectedColumn.id} length={6} />
              </Label>

              <Label>
                Column type
                <br />
                <InputReadOnly>
                  {selectedColumn.data_type.name}
                  {selectedColumn.is_array ? ' (array)' : ''}
                </InputReadOnly>
              </Label>

              <Label>
                Table
                <br />
                <Text>{modifiedColumn.table}</Text>
              </Label>
            </div>
            <div className={styles.gridStyle}>
              <Label>
                Array?
                <br />
                <Checkbox
                  id="is_array"
                  name="is_array"
                  checked={modifiedColumn.is_array}
                  disabled
                />
              </Label>

              <Label>
                Unique Values For User
                <br />
                <Checkbox
                  id="unqiue_values_for_user"
                  name="unqiue_values_for_user"
                  checked={modifiedColumn.constraints.unique_required}
                  disabled
                />
              </Label>

              <Label>
                Unique IDs For User
                <br />
                <Checkbox
                  id="unqiue_ids_for_user"
                  name="unqiue_ids_for_user"
                  checked={modifiedColumn.constraints.unique_id_required}
                  disabled
                />
              </Label>

              <Label>
                Partial Updates
                <br />
                <Checkbox
                  id="allow_partial_updates"
                  name="allow_partial_updates"
                  checked={modifiedColumn.constraints.partial_updates}
                  disabled
                />
              </Label>

              <Label>
                Immutable
                <br />
                <Checkbox
                  id="immutable"
                  name="immutable"
                  checked={modifiedColumn.constraints.immutable_required}
                  disabled
                />
              </Label>

              <Label>
                Unique within Tenant
                <Checkbox
                  name="unique"
                  id="unique"
                  checked={modifiedColumn.index_type === ColumnIndexType.Unique}
                  disabled
                />
              </Label>

              <Label>
                Search Indexed
                <br />
                <Checkbox
                  id="searched_index"
                  name="searched_index"
                  checked={modifiedColumn.search_indexed}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(
                      modifyUserStoreColumn({
                        search_indexed: e.target.checked,
                      })
                    );
                  }}
                />
              </Label>
            </div>
          </>
        ) : (
          <>
            <div className={PageCommon.carddetailsrow}>
              <Label>
                Name
                <br />
                <InputReadOnly>{selectedColumn.name}</InputReadOnly>
              </Label>

              <Label>
                ID
                <br />
                <TextShortener text={selectedColumn.id} length={6} />
              </Label>

              <Label>
                Column Type
                <br />
                <InputReadOnly>
                  {selectedColumn.data_type.name}
                  {selectedColumn.is_array ? ' (array)' : ''}
                </InputReadOnly>
              </Label>

              <Label>
                Table
                <br />
                <InputReadOnly>{modifiedColumn.table}</InputReadOnly>
              </Label>
            </div>

            <div className={styles.gridStyle}>
              <Label>
                Array?
                <br />
                <InputReadOnly
                  type="checkbox"
                  isChecked={modifiedColumn.is_array}
                />
              </Label>

              <Label>
                Unique Values For User
                <br />
                <InputReadOnly
                  type="checkbox"
                  isChecked={modifiedColumn.constraints.unique_required}
                />
              </Label>

              <Label>
                Unique IDs For User
                <br />
                <InputReadOnly
                  type="checkbox"
                  isChecked={modifiedColumn.constraints.unique_id_required}
                />
              </Label>

              <Label>
                Partial Updates
                <br />
                <InputReadOnly
                  type="checkbox"
                  isChecked={modifiedColumn.constraints.partial_updates}
                />
              </Label>

              <Label>
                Immutable
                <br />
                <InputReadOnly
                  type="checkbox"
                  isChecked={modifiedColumn.constraints.immutable_required}
                />
              </Label>

              <Label>
                Unique within Tenant
                <br />
                <InputReadOnly
                  type="checkbox"
                  isChecked={
                    modifiedColumn.index_type === ColumnIndexType.Unique
                  }
                />
              </Label>

              <Label>
                Search Indexed?
                <br />
                <InputReadOnly
                  type="checkbox"
                  isChecked={modifiedColumn.search_indexed}
                />
              </Label>
            </div>
          </>
        )}
      </CardRow>

      <CardRow
        title="Default Transformer"
        tooltip="Default transformer used for this column in accessors."
        collapsible
      >
        <Table
          id="default-transformer-table"
          className={styles.transformertable}
        >
          <TableHead>
            <TableRow key="head">
              <TableRowHead>Transformer</TableRowHead>
              <TableRowHead>Token Access Policy</TableRowHead>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                {isCreatePage || editMode ? (
                  transformers?.data?.length ? (
                    <Select
                      id="selected_transformer"
                      name="selected_transformer"
                      value={defaultTranformerId}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const val = e.target.value;
                        dispatch(modifyUserStoreColumnDefaultTransformer(val));
                      }}
                    >
                      {transformers.data
                        .filter((transformer: Transformer) =>
                          transformerIsValidForAccessorColumn(
                            columnToAccessorColumn(selectedColumn),
                            transformer
                          )
                        )
                        .map((transformer: Transformer) => (
                          <option value={transformer.id} key={transformer.id}>
                            {transformer.name}
                          </option>
                        ))}
                    </Select>
                  ) : fetchingTransformers ? (
                    <LoaderDots
                      size="small"
                      assistiveText="Loading transformers"
                    />
                  ) : (
                    <InlineNotification theme="alert">
                      Error fetching transformers
                    </InlineNotification>
                  )
                ) : (
                  <InputReadOnly>
                    <Link
                      href={`/transformers/${selectedColumn.default_transformer?.id}/latest${cleanQuery}`}
                      title="View, edit, or test this transformer"
                    >
                      {selectedColumn.default_transformer?.name}
                    </Link>
                  </InputReadOnly>
                )}
              </TableCell>
              <TableCell>
                {showTokenAccessPolicy ? (
                  isCreatePage || editMode ? (
                    allAccessPolicies?.data ? (
                      <Select
                        id="selected_token_access_policy"
                        name="selected_token_access_policy"
                        value={modifiedColumn.default_token_access_policy?.id}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const val = e.target.value;
                          dispatch(
                            modifyUserStoreColumn({
                              default_token_access_policy: { id: val },
                            })
                          );
                        }}
                      >
                        <option key="select_an_access_policy">
                          Select an access policy
                        </option>
                        {allAccessPolicies.data.map((policy: AccessPolicy) => (
                          <option value={policy.id} key={policy.id}>
                            {truncateWithEllipsis(policy.name, 35)}
                          </option>
                        ))}
                      </Select>
                    ) : fetchingAllAccessPolicies ? (
                      <LoaderDots
                        size="small"
                        assistiveText="Loading policies"
                      />
                    ) : (
                      <InlineNotification theme="alert">
                        Error fetching access policies
                      </InlineNotification>
                    )
                  ) : (
                    <InputReadOnly>
                      <Link title="View, edit, or test this access policy">
                        {selectedColumn.default_token_access_policy?.name}
                      </Link>
                    </InputReadOnly>
                  )
                ) : (
                  'n/a'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardRow>

      <CardRow
        title="Access policy"
        tooltip={
          <>
            Select an access policy describing when this column can be accessed.
            <a
              href="https://docs.userclouds.com/docs/access-policies-1"
              title="UserClouds documentation for access policies"
              target="new"
              className={PageCommon.link}
            >
              Learn more here.
            </a>
          </>
        }
        collapsible
      >
        <PolicyComposer
          policy={modifiedAccessPolicy}
          changeAccessPolicyAction={modifyAccessPolicy}
          readOnly={!editMode && !isCreatePage}
        />
      </CardRow>
      <Dialog
        id="createPolicyTemplateDialog"
        title="Create Policy Template"
        description="Create a new template and add it to your composite policy. The template will be saved to your library for re-use later."
      >
        {policyTemplateDialogIsOpen && (
          <DialogBody>
            <PolicyTemplateForm
              editableTemplate={newTemplate || blankPolicyTemplate()}
              savedTemplate={undefined}
              saveTemplate={createAccessPolicyTemplateForAccessPolicy(() =>
                dialog.close()
              )}
              onCancel={() => {
                dialog?.close();
              }}
              searchParams={query}
            />
          </DialogBody>
        )}
      </Dialog>

      <ConnectedPolicyChooserDialog
        policy={modifiedAccessPolicy}
        tokenRes={false}
        changeAccessPolicyAction={modifyAccessPolicy}
        createNewPolicyTemplateHandler={() => dialog.showModal()}
      />

      {showTokenAccessPolicy && (
        <ConnectedPolicyChooserDialog
          policy={modifiedTokenAccessPolicy}
          tokenRes
          changeAccessPolicyAction={modifyTokenAccessPolicy}
          createNewPolicyTemplateHandler={() => dialog.showModal()}
        />
      )}

      <>
        {saveError && (
          <InlineNotification theme="alert">{saveError}</InlineNotification>
        )}
        {saveSuccess && (
          <InlineNotification theme="success">{saveSuccess}</InlineNotification>
        )}
      </>

      {!isCreatePage && (
        <CardRow
          title="Accessors that Read Column"
          tooltip={
            <>
              {
                'Select an access policy describing when this column can be accessed. '
              }
              <a
                href="https://docs.userclouds.com/docs/accessors-read-apis"
                title="UserClouds documentation for accessors (read APIs)"
                target="new"
                className={PageCommon.link}
              >
                Learn more here.
              </a>
            </>
          }
          collapsible
        >
          <br />
          <Checkbox
            id="include_autogenerated"
            checked={includeAutogeneratedAccessors}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(
                toggleAccessorListIncludeAutogenerated(e.currentTarget.checked)
              );
            }}
          >
            Include autogenerated
          </Checkbox>
          <br />
          <ConnectedAccessorTable />
        </CardRow>
      )}

      {!isCreatePage && (
        <CardRow
          title="Purpose Settings"
          tooltip="Define which purposes are allowed for this column. Define the lifetime of each purpose for this column (after which the consented purpose will be automatically deleted). Configure how long data is retained (soft-deleted) for each purpose after it is deleted. Data will be hard-deleted X days after the deletion event, where X is the longest duration specified for the column."
          collapsible
        >
          <>
            <div />
            <ConnectedPurposeSettings />
          </>
        </CardRow>
      )}
    </Card>
  );
};
const ConnectedColumnDetails = connect((state: RootState) => ({
  allAccessPolicies: state.allAccessPolicies,
  accessPolicy: state.currentAccessPolicy,
  editMode: state.columnEditMode,
  featureFlags: state.featureFlags,
  fetchError: state.fetchingColumnError,
  fetching: state.fetchingColumn,
  fetchingAllAccessPolicies: state.fetchingAllAccessPolicies,
  fetchingTransformers: state.fetchingTransformers,
  includeAutogeneratedAccessors: state.accessorListIncludeAutogenerated,
  isDirty: state.columnIsDirty,
  modifiedAccessPolicy: state.modifiedAccessPolicy,
  modifiedColumn: state.modifiedColumn,
  modifiedTokenAccessPolicy: state.modifiedTokenAccessPolicy,
  newTemplate: state.policyTemplateToCreate,
  policyTemplateDialogIsOpen: state.policyTemplateDialogIsOpen,
  query: state.query,
  saveError: state.saveColumnError,
  saveSuccess: state.savingColumnSuccess,
  saving: state.savingColumn,
  selectedColumn: state.selectedColumn,
  selectedCompanyID: state.selectedCompanyID,
  selectedTenant: state.selectedTenant,
  selectedTenantID: state.selectedTenantID,
  tokenAccessPolicy: state.currentTokenAccessPolicy,
  transformers: state.transformers,
}))(ColumnDetails);

const PurposeRow = ({
  purpose,
  durations,
  supportedUnits,
  editMode,
  dispatch,
}: {
  purpose: Purpose;
  durations: PurposeRetentionDuration[];
  supportedUnits: DurationUnit[] | undefined;
  editMode: boolean;
  dispatch: AppDispatch;
}) => {
  const matchingDuration = durations.find(
    (duration) => duration.purpose_id === purpose.id
  );
  return (
    <TableRow key={purpose.id}>
      <TableCell key="purposeName">{purpose.name}</TableCell>
      <TableCell key="purposeRetentionDuration">
        {editMode ? (
          <>
            <TextInput
              type="number"
              max={1000}
              min={
                matchingDuration?.duration.unit === DurationUnit.Indefinite
                  ? 0
                  : 1
              }
              value={matchingDuration?.duration.duration}
              size="medium"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (matchingDuration) {
                  dispatch(
                    modifyRetentionDuration({
                      ...matchingDuration,
                      duration: {
                        duration: parseInt(e.target.value, 10),
                        unit: matchingDuration.duration.unit,
                      },
                      use_default: false,
                    })
                  );
                }
              }}
            />
            <Select
              name="retention_quantity_unit"
              value={matchingDuration?.duration.unit}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (matchingDuration) {
                  const unit = e.target.value as DurationUnit;
                  dispatch(
                    modifyRetentionDuration({
                      ...matchingDuration,
                      duration: {
                        unit,
                        duration:
                          unit === DurationUnit.Indefinite
                            ? 0
                            : matchingDuration.duration.duration,
                      },
                      use_default: false,
                    })
                  );
                }
              }}
              className={GlobalStyles['ml-4']}
            >
              {(
                supportedUnits ||
                Object.values(DurationUnit).filter(
                  (u: string) => u !== DurationUnit.Indefinite
                )
              ).map((unit: string) => (
                <option value={unit} key={unit}>
                  {unit}s
                </option>
              ))}
            </Select>
          </>
        ) : (
          <InputReadOnly>
            {matchingDuration
              ? displayDuration(
                  matchingDuration.duration,
                  DurationType.SoftDeleted
                )
              : "don't retain"}
          </InputReadOnly>
        )}
      </TableCell>
    </TableRow>
  );
};

const PurposeSettings = ({
  selectedCompanyID,
  selectedTenant,
  purposes,
  durations,
  supportedUnits,
  isFetching,
  fetchError,
  editMode,
  saveSuccess,
  saveError,
  dispatch,
}: {
  selectedCompanyID: string | undefined;
  selectedTenant: SelectedTenant | undefined;
  purposes: PaginatedResult<Purpose> | undefined;
  durations: PurposeRetentionDuration[];
  supportedUnits: DurationUnit[] | undefined;
  isFetching: boolean;
  fetchError: string;
  editMode: boolean;
  saveSuccess: boolean;
  saveError: string;
  dispatch: AppDispatch;
}) => {
  return (
    <>
      {saveSuccess && (
        <InlineNotification theme="success">
          Successfully saved retention durations.
        </InlineNotification>
      )}
      {saveError && (
        <InlineNotification theme="alert">{saveError}</InlineNotification>
      )}
      {purposes ? (
        purposes.data?.length ? (
          <Table className={styles.columnpurposestable}>
            <TableHead>
              <TableRow>
                <TableRowHead key="purposeName">Access purpose</TableRowHead>
                <TableRowHead key="postDeletionRetention">
                  Retain after deletion for
                </TableRowHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {purposes.data.map((purpose: Purpose) => (
                <PurposeRow
                  purpose={purpose}
                  durations={durations}
                  supportedUnits={supportedUnits}
                  editMode={editMode}
                  dispatch={dispatch}
                  key={purpose.id}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyState
            title="Nothing to display"
            subTitle="No purposes have been specified yet for this tenant."
            image={<IconFocus3Line size="large" />}
          >
            {selectedTenant?.is_admin && (
              <Button theme="secondary">
                <Link
                  href={`/purposes/create?company_id=${encodeURIComponent(
                    selectedCompanyID as string
                  )}&tenant_id=${encodeURIComponent(selectedTenant!.id)}`}
                  title="Add a new purpose"
                  applyStyles={false}
                >
                  + Add purpose
                </Link>
              </Button>
            )}
          </EmptyState>
        )
      ) : isFetching ? (
        <Text>Loading ...</Text>
      ) : (
        <InlineNotification>
          {fetchError || 'Something went wrong'}
        </InlineNotification>
      )}
    </>
  );
};

const ConnectedPurposeSettings = connect((state: RootState) => ({
  selectedCompanyID: state.selectedCompanyID,
  selectedTenant: state.selectedTenant,
  modifiedColumn: state.modifiedColumn,
  purposes: state.purposes,
  durations: state.modifiedRetentionDurations,
  supportedUnits: state.columnRetentionDurations?.supported_duration_units,
  isFetching: state.fetchingPurposes || state.fetchingColumnRetentionDurations,
  fetchError: state.purposesFetchError || state.columnDurationsFetchError, // TODO: do we need to treat these separately?
  editMode: state.columnPurposesEditMode,
  isSaving: state.savingColumnRetentionDurations,
  saveSuccess: state.retentionDurationsSaveSuccess,
  saveError: state.retentionDurationsSaveError,
  isDirty: state.purposeSettingsAreDirty,
  featureFlags: state.featureFlags,
}))(PurposeSettings);

const ConnectedAccessorTable = connect((state: RootState) => ({
  selectedTenant: state.selectedTenant,
  accessors: state.accessorsForColumn,
  isFetching: state.fetchingAccessors,
  fetchError: state.fetchAccessorsError,
  deleteQueue: state.accessorsToDelete,
  saveSuccess: state.bulkUpdateAccessorsSuccess,
  saveErrors: state.bulkUpdateAccessorsErrors,
  query: state.query,
}))(AccessorTable);

const ColumnPage = ({
  selectedTenantID,
  columns,
  dataTypes,
  location,
  query,
  routeParams,
  selectedColumn,
  selectedTenant,
  selectedCompanyID,
  modifiedColumn,
  fetching,
  saving,
  editMode,
  includeAutogenerated,
  isDirty,
  accessPolicyIsDirty,
  durations,
  purposeFetching,
  purposeSaving,
  purposeIsDirty,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  columns: PaginatedResult<Column> | undefined;
  dataTypes: PaginatedResult<DataType> | undefined;
  location: URL;
  query: URLSearchParams;
  routeParams: Record<string, string>;
  selectedColumn: Column | undefined;
  selectedTenant: SelectedTenant | undefined;
  selectedCompanyID: string | undefined;
  modifiedColumn: Column | undefined;
  fetching: boolean;
  saving: boolean;
  editMode: boolean;
  includeAutogenerated: boolean;
  isDirty: boolean;
  accessPolicyIsDirty: boolean;
  durations: PurposeRetentionDuration[];
  purposeFetching: boolean;
  purposeSaving: boolean;
  purposeIsDirty: boolean;
  dispatch: AppDispatch;
}) => {
  const cleanQuery = makeCleanPageLink(query);
  const { columnID } = routeParams;
  const { pathname } = location;
  const isCreatePage = pathname.indexOf('create') > -1;

  useEffect(() => {
    if (selectedTenantID) {
      if (isCreatePage) {
        dispatch(fetchUserStoreColumnSuccess(blankColumn()));
      } else if (columnID) {
        const matchingColumn = columns?.data.find(
          (col: Column) => col.id === columnID
        );
        if (matchingColumn) {
          dispatch(changeSelectedColumn(matchingColumn));
        } else {
          dispatch(fetchColumn(selectedTenantID, columnID));
        }
        dispatch(
          fetchRetentionDurationsForColumn(
            selectedTenantID,
            columnID,
            DurationType.SoftDeleted
          )
        );
      }
      dispatch(fetchPurposes(selectedTenantID, new URLSearchParams()));
    }
  }, [selectedTenantID, columnID, columns, query, isCreatePage, dispatch]);

  useEffect(() => {
    if (selectedTenantID && !isCreatePage) {
      dispatch(
        fetchAccessorsForColumn(
          selectedTenantID,
          columnID,
          query,
          includeAutogenerated
        )
      );
    }
  }, [
    selectedTenantID,
    columnID,
    query,
    isCreatePage,
    includeAutogenerated,
    dispatch,
  ]);

  useEffect(() => {
    if (selectedTenantID) {
      const params = new URLSearchParams();
      params.set('data_types_limit', String(MAX_LIMIT));
      dispatch(fetchDataTypes(selectedTenantID, params));
      dispatch(
        fetchTransformers(selectedTenantID, new URLSearchParams(), 1000)
      );
    }
  }, [selectedTenantID, dispatch]);

  useEffect(() => {
    if (selectedTenantID && selectedColumn && !isCreatePage) {
      dispatch(
        fetchAccessPolicy(selectedTenantID, selectedColumn.access_policy.id)
      );
      if (
        selectedColumn.default_token_access_policy &&
        selectedColumn.default_token_access_policy.id !== NilUuid
      ) {
        dispatch(
          fetchTokenAccessPolicy(
            selectedTenantID,
            selectedColumn.default_token_access_policy.id
          )
        );
      } else {
        dispatch(getTokenAccessPolicySuccess(blankPolicy()));
      }
    }
  }, [selectedTenantID, selectedColumn, dispatch, isCreatePage]);

  return (
    <>
      <div className={PageCommon.listviewtablecontrols}>
        <Heading
          size={2}
          headingLevel={1}
          className={PageCommon.listviewtablecontrolsTitle}
        >
          {isCreatePage
            ? 'Create Column:'
            : `${editMode ? 'Edit' : 'View'} Column:`}{' '}
          <b>{selectedColumn?.name || 'New Column'}</b>
        </Heading>
        <div className={PageCommon.listviewtablecontrolsToolTip}>
          <ToolTip>
            <>
              {isCreatePage
                ? 'Create a new User Store column for representing user data. '
                : 'Manage user data as stored in this column. '}
              <a
                href="https://docs.userclouds.com/docs/manage-your-columns"
                title="UserClouds documentation for Column Management key concepts"
                target="new"
                className={PageCommon.link}
              >
                Learn more here.
              </a>
            </>
          </ToolTip>
        </div>
        <ButtonGroup className={PageCommon.listviewtablecontrolsButtonGroup}>
          {!isCreatePage ? (
            selectedTenant?.is_admin &&
            selectedColumn &&
            !selectedColumn.is_system && (
              <>
                {!editMode ? (
                  <Button
                    theme="primary"
                    size="small"
                    onClick={() => {
                      dispatch(toggleColumnEditMode());
                      dispatch(toggleColumnPurposesEditMode());
                    }}
                    disabled={selectedColumn.is_system}
                  >
                    Edit Column
                  </Button>
                ) : (
                  <>
                    <Button
                      size="small"
                      theme="secondary"
                      disabled={saving || selectedColumn.is_system}
                      isLoading={
                        saving || fetching || purposeSaving || purposeFetching
                      }
                      onClick={() => {
                        if (
                          (!isDirty && purposeIsDirty) ||
                          window.confirm(
                            'You have unsaved changes. Are you sure you want to cancel editing?'
                          )
                        ) {
                          dispatch(toggleColumnEditMode(false));
                          dispatch(toggleColumnPurposesEditMode(false));
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      theme="primary"
                      size="small"
                      disabled={
                        saving ||
                        purposeSaving ||
                        (!isDirty && !purposeIsDirty && !accessPolicyIsDirty) ||
                        selectedColumn.is_system
                      }
                      isLoading={
                        saving || fetching || purposeSaving || purposeFetching
                      }
                      onClick={() => {
                        if (modifiedColumn) {
                          dispatch(
                            updateColumn(selectedTenant.id, modifiedColumn)
                          );
                        }
                        if (selectedTenant && modifiedColumn) {
                          dispatch(
                            updateRetentionDurationsForColumn(
                              selectedTenant.id,
                              modifiedColumn.id,
                              DurationType.SoftDeleted,
                              durations
                            )
                          );
                        }
                      }}
                    >
                      Save changes
                    </Button>
                  </>
                )}
              </>
            )
          ) : (
            <>
              <Button
                theme="secondary"
                size="small"
                disabled={saving || purposeSaving}
                isLoading={
                  saving || fetching || purposeSaving || purposeFetching
                }
                onClick={() => {
                  if (
                    (!isDirty && !purposeIsDirty) ||
                    window.confirm(
                      'You have unsaved changes. Are you sure you want to cancel editing?'
                    )
                  ) {
                    redirect(`/columns?${cleanQuery}`);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                theme="primary"
                size="small"
                disabled={!modifiedColumn || modifiedColumn.name === ''}
                onClick={() => {
                  if (modifiedColumn) {
                    selectedTenant &&
                      dispatch(
                        createColumn(
                          selectedTenant.id,
                          selectedCompanyID,
                          modifiedColumn
                        )
                      );
                  }
                }}
                isLoading={saving}
              >
                Save column
              </Button>
            </>
          )}
        </ButtonGroup>
      </div>

      {dataTypes && (
        <ConnectedColumnDetails
          isCreatePage={isCreatePage}
          dataTypes={dataTypes.data}
        />
      )}
    </>
  );
};

export default connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  columns: state.userStoreDisplayColumns,
  selectedColumn: state.selectedColumn,
  location: state.location,
  query: state.query,
  routeParams: state.routeParams,
  featureFlags: state.featureFlags,
  selectedTenant: state.selectedTenant,
  selectedCompanyID: state.selectedCompanyID,
  modifiedColumn: state.modifiedColumn,
  fetching: state.fetchingColumn,
  fetchError: state.fetchingColumnError,
  saving: state.savingColumn,
  saveSuccess: state.savingColumnSuccess,
  saveError: state.saveColumnError,
  editMode: state.columnEditMode,
  includeAutogenerated: state.accessorListIncludeAutogenerated,
  isDirty: state.columnIsDirty || state.tokenAccessPolicyIsDirty,
  accessPolicyIsDirty: state.accessPolicyIsDirty,
  durations: state.modifiedRetentionDurations,
  dataTypes: state.dataTypes,
  supportedUnits: state.columnRetentionDurations?.supported_duration_units,
  purposeFetching:
    state.fetchingPurposes || state.fetchingColumnRetentionDurations,
  purposeFetchError:
    state.purposesFetchError || state.columnDurationsFetchError, // TODO: do we need to treat these separately?
  purposeEditMode: state.columnPurposesEditMode,
  purposeSaving: state.savingColumnRetentionDurations,
  purposeSaveSuccess: state.retentionDurationsSaveSuccess,
  purposeSaveError: state.retentionDurationsSaveError,
  purposeIsDirty: state.purposeSettingsAreDirty,
}))(ColumnPage);
