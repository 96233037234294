import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Card,
  CardRow,
  CardFooter,
  Checkbox,
  EmptyState,
  GlobalStyles,
  IconFocus3Line,
  InputReadOnly,
  Label,
  Select,
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowHead,
  Text,
  TextInput,
} from '@userclouds/ui-component-lib';

import PaginatedResult from '../models/PaginatedResult';
import { SelectedTenant } from '../models/Tenant';
import {
  Column,
  ColumnIndexType,
  blankColumn,
  getSelectedDataType,
} from '../models/TenantUserStoreConfig';
import Purpose from '../models/Purpose';
import {
  PurposeRetentionDuration,
  DurationUnit,
  DurationType,
  displayDuration,
} from '../models/ColumnRetentionDurations';
import { RootState, AppDispatch } from '../store';
import {
  fetchUserStoreColumnSuccess,
  modifyUserStoreColumn,
  toggleColumnEditMode,
  toggleColumnPurposesEditMode,
  modifyRetentionDuration,
  changeSelectedColumn,
} from '../actions/userstore';
import {
  createColumn,
  fetchColumn,
  updateColumn,
  fetchRetentionDurationsForColumn,
  updateRetentionDurationsForColumn,
  fetchDataTypes,
} from '../thunks/userstore';
import { fetchAccessorsForColumn } from '../thunks/accessors';
import { fetchPurposes } from '../thunks/purposes';
import { makeCleanPageLink } from '../AppNavigation';
import Link from '../controls/Link';
import { redirect } from '../routing';
import AccessorList from './AccessorList';
import PageCommon from './PageCommon.module.css';
import { DataType } from '../models/DataType';
import { MAX_LIMIT } from '../controls/PaginationHelper';

const ColumnDetails = ({
  selectedTenant,
  selectedCompanyID,
  selectedColumn,
  modifiedColumn,
  dataTypes,
  fetching,
  fetchError,
  saving,
  saveSuccess,
  saveError,
  editMode,
  isCreatePage,
  isDirty,
  query,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  selectedCompanyID: string | undefined;
  selectedColumn: Column | undefined;
  modifiedColumn: Column | undefined;
  dataTypes: DataType[];
  fetching: boolean;
  fetchError: string;
  saving: boolean;
  saveSuccess: string;
  saveError: string;
  editMode: boolean;
  isCreatePage: boolean;
  isDirty: boolean;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  const cleanQuery = makeCleanPageLink(query);
  if (!selectedTenant) {
    return (
      <InlineNotification theme="alert">
        Unable to find tenant.
      </InlineNotification>
    );
  }

  if (fetchError) {
    return <InlineNotification theme="alert">{fetchError}</InlineNotification>;
  }
  if (!selectedColumn || !modifiedColumn) {
    return <Text>Loading ...</Text>;
  }
  return (
    <Card
      title="Basic Details"
      id="basicDetails"
      lockedMessage={
        selectedColumn.is_system ? 'System column details cannot be edited' : ''
      }
    >
      {isCreatePage ? (
        modifiedColumn && (
          <div className={PageCommon.carddetailsrow}>
            <Label>
              Table
              <Text id="table" name="table" value={modifiedColumn.table} />
            </Label>
            <Label>
              Name
              <TextInput
                id="name"
                name="name"
                value={modifiedColumn.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(modifyUserStoreColumn({ name: e.target.value }));
                }}
              />
            </Label>
            <Label>
              Column type
              <br />
              <Select
                name="data_type"
                value={modifiedColumn.data_type.name}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedDataType = getSelectedDataType(
                    e.target.value,
                    dataTypes
                  );
                  if (selectedDataType) {
                    dispatch(
                      modifyUserStoreColumn({
                        data_type: {
                          id: selectedDataType.id,
                          name: selectedDataType.name,
                        },
                      })
                    );
                  }
                }}
                required
              >
                {dataTypes.map((type) => (
                  <option value={type.name} key={type.name}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </Label>
            <Label>
              Array?
              <Checkbox
                id="is_array"
                name="is_array"
                checked={modifiedColumn.is_array}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(
                    modifyUserStoreColumn({ is_array: e.target.checked })
                  );
                }}
              />
            </Label>
            <Label>
              ID
              <InputReadOnly>{selectedColumn.id}</InputReadOnly>
            </Label>
            <Label>
              Unique or indexed?
              <br />
              <Select
                name="index_type"
                value={modifiedColumn.index_type}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  dispatch(
                    modifyUserStoreColumn({ index_type: e.target.value })
                  );
                }}
              >
                {(
                  Object.keys(ColumnIndexType) as Array<
                    keyof typeof ColumnIndexType
                  >
                ).map((type) => (
                  <option value={ColumnIndexType[type]} key={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </Label>
            <Label>
              Indexed?
              <InputReadOnly>
                {modifiedColumn.index_type === ColumnIndexType.Indexed
                  ? 'Yes'
                  : 'No'}
              </InputReadOnly>
            </Label>
          </div>
        )
      ) : editMode ? (
        <div className={PageCommon.carddetailsrow}>
          <Label>
            Name
            <TextInput
              id="name"
              name="name"
              value={modifiedColumn.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(modifyUserStoreColumn({ name: e.target.value }));
              }}
            />
          </Label>
          <Label>
            Column type
            <InputReadOnly>
              {selectedColumn.data_type.name}
              {selectedColumn.is_array ? ' (array)' : ''}
            </InputReadOnly>
          </Label>
          <Label>
            ID
            <InputReadOnly>{selectedColumn.id}</InputReadOnly>
          </Label>
          {selectedColumn.index_type === ColumnIndexType.Indexed ? (
            <Label>
              Unique?
              <InputReadOnly>No</InputReadOnly>
            </Label>
          ) : (
            <Label>
              Unique?
              <br />
              <Select
                name="index_type"
                value={modifiedColumn.index_type}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  dispatch(
                    modifyUserStoreColumn({ index_type: e.target.value })
                  );
                }}
              >
                {(
                  Object.keys(ColumnIndexType) as Array<
                    keyof typeof ColumnIndexType
                  >
                ).map(
                  (type) =>
                    type !== 'Indexed' && (
                      <option value={ColumnIndexType[type]} key={type}>
                        {type}
                      </option>
                    )
                )}
              </Select>
            </Label>
          )}
          <Label>
            Indexed?
            <InputReadOnly>
              {selectedColumn.index_type === ColumnIndexType.Indexed
                ? 'Yes'
                : 'No'}
            </InputReadOnly>
          </Label>
        </div>
      ) : (
        <div className={PageCommon.carddetailsrow}>
          <Label>
            Name
            <InputReadOnly>{selectedColumn.name}</InputReadOnly>
          </Label>
          <Label>
            Column type
            <InputReadOnly>
              {selectedColumn.data_type.name}
              {selectedColumn.is_array ? ' (array)' : ''}
            </InputReadOnly>
          </Label>
          <Label>
            ID
            <InputReadOnly>{selectedColumn.id}</InputReadOnly>
          </Label>
          <Label>
            Unique?
            <InputReadOnly>
              {selectedColumn.index_type === ColumnIndexType.Unique
                ? 'Yes'
                : 'No'}
            </InputReadOnly>
          </Label>
          <Label>
            Indexed?
            <InputReadOnly>
              {selectedColumn.index_type === ColumnIndexType.Indexed
                ? 'Yes'
                : 'No'}
            </InputReadOnly>
          </Label>
        </div>
      )}
      <>
        {saveError && (
          <InlineNotification theme="alert">{saveError}</InlineNotification>
        )}
        {saveSuccess && (
          <InlineNotification theme="success">{saveSuccess}</InlineNotification>
        )}
      </>
      <CardFooter>
        <ButtonGroup>
          {!isCreatePage ? (
            selectedTenant?.is_admin &&
            !selectedColumn.is_system && (
              <>
                {!editMode ? (
                  <Button
                    theme="secondary"
                    onClick={() => {
                      dispatch(toggleColumnEditMode());
                    }}
                    disabled={selectedColumn.is_system}
                  >
                    Edit basic details
                  </Button>
                ) : (
                  <>
                    <Button
                      theme="primary"
                      disabled={saving || !isDirty || selectedColumn.is_system}
                      isLoading={saving || fetching}
                      onClick={() => {
                        if (modifiedColumn) {
                          dispatch(
                            updateColumn(selectedTenant.id, modifiedColumn)
                          );
                        }
                      }}
                    >
                      Save changes
                    </Button>
                    <Button
                      theme="secondary"
                      disabled={saving || selectedColumn.is_system}
                      isLoading={saving || fetching}
                      onClick={() => {
                        if (
                          !isDirty ||
                          window.confirm(
                            'You have unsaved changes. Are you sure you want to cancel editing?'
                          )
                        ) {
                          dispatch(toggleColumnEditMode(false));
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </>
            )
          ) : (
            <>
              <Button
                theme="primary"
                disabled={!modifiedColumn || modifiedColumn.name === ''}
                onClick={() => {
                  if (modifiedColumn) {
                    dispatch(
                      createColumn(
                        selectedTenant.id,
                        selectedCompanyID,
                        modifiedColumn
                      )
                    );
                  }
                }}
                isLoading={saving}
              >
                Create column
              </Button>
              <Button
                theme="secondary"
                disabled={saving}
                isLoading={saving || fetching}
                onClick={() => {
                  if (
                    !isDirty ||
                    window.confirm(
                      'You have unsaved changes. Are you sure you want to cancel editing?'
                    )
                  ) {
                    redirect(`/columns?${cleanQuery}`);
                  }
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
};
const ConnectedColumnDetails = connect((state: RootState) => ({
  selectedTenant: state.selectedTenant,
  selectedCompanyID: state.selectedCompanyID,
  selectedColumn: state.selectedColumn,
  modifiedColumn: state.modifiedColumn,
  fetching: state.fetchingColumn,
  fetchError: state.fetchingColumnError,
  saving: state.savingColumn,
  saveSuccess: state.savingColumnSuccess,
  saveError: state.saveColumnError,
  editMode: state.columnEditMode,
  isDirty: state.columnIsDirty,
  query: state.query,
}))(ColumnDetails);

const PurposeRow = ({
  purpose,
  durations,
  supportedUnits,
  editMode,
  dispatch,
}: {
  purpose: Purpose;
  durations: PurposeRetentionDuration[];
  supportedUnits: DurationUnit[] | undefined;
  editMode: boolean;
  dispatch: AppDispatch;
}) => {
  const matchingDuration = durations.find(
    (duration) => duration.purpose_id === purpose.id
  );
  return (
    <TableRow key={purpose.id}>
      <TableCell key="purposeName">{purpose.name}</TableCell>
      <TableCell key="purposeRetentionDuration">
        {editMode ? (
          <>
            <TextInput
              type="number"
              max={1000}
              min={
                matchingDuration?.duration.unit === DurationUnit.Indefinite
                  ? 0
                  : 1
              }
              value={matchingDuration?.duration.duration}
              size="medium"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (matchingDuration) {
                  dispatch(
                    modifyRetentionDuration({
                      ...matchingDuration,
                      duration: {
                        duration: parseInt(e.target.value, 10),
                        unit: matchingDuration.duration.unit,
                      },
                      use_default: false,
                    })
                  );
                }
              }}
            />
            <Select
              name="retention_quantity_unit"
              value={matchingDuration?.duration.unit}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (matchingDuration) {
                  const unit = e.target.value as DurationUnit;
                  dispatch(
                    modifyRetentionDuration({
                      ...matchingDuration,
                      duration: {
                        unit,
                        duration:
                          unit === DurationUnit.Indefinite
                            ? 0
                            : matchingDuration.duration.duration,
                      },
                      use_default: false,
                    })
                  );
                }
              }}
              className={GlobalStyles['ml-4']}
            >
              {(
                supportedUnits ||
                Object.values(DurationUnit).filter(
                  (u: string) => u !== DurationUnit.Indefinite
                )
              ).map((unit: string) => (
                <option value={unit} key={unit}>
                  {unit}s
                </option>
              ))}
            </Select>
          </>
        ) : (
          <InputReadOnly>
            {matchingDuration
              ? displayDuration(
                  matchingDuration.duration,
                  DurationType.SoftDeleted
                )
              : "don't retain"}
          </InputReadOnly>
        )}
      </TableCell>
    </TableRow>
  );
};

const PurposeSettings = ({
  selectedCompanyID,
  selectedTenant,
  modifiedColumn,
  purposes,
  durations,
  supportedUnits,
  isFetching,
  fetchError,
  editMode,
  isSaving,
  saveSuccess,
  saveError,
  isDirty,
  dispatch,
}: {
  selectedCompanyID: string | undefined;
  selectedTenant: SelectedTenant | undefined;
  modifiedColumn: Column | undefined;
  purposes: PaginatedResult<Purpose> | undefined;
  durations: PurposeRetentionDuration[];
  supportedUnits: DurationUnit[] | undefined;
  isFetching: boolean;
  fetchError: string;
  editMode: boolean;
  isSaving: boolean;
  saveSuccess: boolean;
  saveError: string;
  isDirty: boolean;
  dispatch: AppDispatch;
}) => {
  return (
    <Card
      id="purposeSettings"
      title="Purpose Settings"
      description="Define which purposes are allowed for this column. Define the lifetime of each purpose for this column (after which the consented purpose will be automatically deleted). Configure how long data is retained (soft-deleted) for each purpose after it is deleted. Data will be hard-deleted X days after the deletion event, where X is the longest duration specified for the column."
      isDirty={editMode}
    >
      {saveSuccess && (
        <InlineNotification theme="success">
          Successfully saved retention durations.
        </InlineNotification>
      )}
      {saveError && (
        <InlineNotification theme="alert">{saveError}</InlineNotification>
      )}
      {purposes ? (
        purposes.data?.length ? (
          <>
            <CardRow>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableRowHead key="purposeName">
                      Access purpose
                    </TableRowHead>
                    <TableRowHead key="postDeletionRetention">
                      Retain after deletion for
                    </TableRowHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purposes.data.map((purpose: Purpose) => (
                    <PurposeRow
                      purpose={purpose}
                      durations={durations}
                      supportedUnits={supportedUnits}
                      editMode={editMode}
                      dispatch={dispatch}
                      key={purpose.id}
                    />
                  ))}
                </TableBody>
              </Table>
            </CardRow>
            {selectedTenant?.is_admin && (
              <CardFooter>
                <ButtonGroup>
                  {!editMode ? (
                    <Button
                      theme="secondary"
                      isLoading={isFetching}
                      onClick={() => {
                        dispatch(toggleColumnPurposesEditMode(true));
                      }}
                    >
                      Edit purposes
                    </Button>
                  ) : (
                    <>
                      <Button
                        theme="primary"
                        isLoading={isFetching || isSaving}
                        disabled={!isDirty || isFetching || isSaving}
                        onClick={() => {
                          if (selectedTenant && modifiedColumn) {
                            dispatch(
                              updateRetentionDurationsForColumn(
                                selectedTenant.id,
                                modifiedColumn.id,
                                DurationType.SoftDeleted,
                                durations
                              )
                            );
                          }
                        }}
                      >
                        Save changes
                      </Button>
                      <Button
                        theme="secondary"
                        isLoading={isFetching || isSaving}
                        disabled={isFetching || isSaving}
                        onClick={() => {
                          if (
                            !isDirty ||
                            window.confirm(
                              'You have unsaved changes. Are you sure you want to cancel editing?'
                            )
                          ) {
                            dispatch(toggleColumnPurposesEditMode(false));
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </CardFooter>
            )}
          </>
        ) : (
          <CardRow>
            <EmptyState
              title="Nothing to display"
              subTitle="No purposes have been specified yet for this tenant."
              image={<IconFocus3Line size="large" />}
            >
              {selectedTenant?.is_admin && (
                <Button theme="secondary">
                  <Link
                    href={`/purposes/create?company_id=${encodeURIComponent(
                      selectedCompanyID as string
                    )}&tenant_id=${encodeURIComponent(selectedTenant!.id)}`}
                    title="Add a new purpose"
                    applyStyles={false}
                  >
                    + Add purpose
                  </Link>
                </Button>
              )}
            </EmptyState>
          </CardRow>
        )
      ) : isFetching ? (
        <Text>Loading ...</Text>
      ) : (
        <InlineNotification>
          {fetchError || 'Something went wrong'}
        </InlineNotification>
      )}
    </Card>
  );
};

const ConnectedPurposeSettings = connect((state: RootState) => ({
  selectedCompanyID: state.selectedCompanyID,
  selectedTenant: state.selectedTenant,
  modifiedColumn: state.modifiedColumn,
  purposes: state.purposes,
  durations: state.modifiedRetentionDurations,
  supportedUnits: state.columnRetentionDurations?.supported_duration_units,
  isFetching: state.fetchingPurposes || state.fetchingColumnRetentionDurations,
  fetchError: state.purposesFetchError || state.columnDurationsFetchError, // TODO: do we need to treat these separately?
  editMode: state.columnPurposesEditMode,
  isSaving: state.savingColumnRetentionDurations,
  saveSuccess: state.retentionDurationsSaveSuccess,
  saveError: state.retentionDurationsSaveError,
  isDirty: state.purposeSettingsAreDirty,
}))(PurposeSettings);

const ConnectedAccessorList = connect((state: RootState) => ({
  selectedCompanyID: state.selectedCompanyID,
  selectedTenant: state.selectedTenant,
  accessors: state.accessorsForColumn,
  userStoreColumns: state.userStoreColumns,
  isFetching: state.fetchingAccessors,
  fetchError: state.fetchAccessorsError,
  deleteQueue: state.accessorsToDelete,
  editMode: false,
  includeAutogenerated: state.accessorListIncludeAutogenerated,
  isSaving: state.updatingAccessors,
  saveSuccess: state.bulkUpdateAccessorsSuccess,
  saveErrors: state.bulkUpdateAccessorsErrors,
  query: state.query,
  accessorSearchFilter: state.accessorSearchFilter,
}))(AccessorList);

const ColumnDetailPage = ({
  selectedTenantID,
  columns,
  dataTypes,
  location,
  query,
  includeAutogenerated,
  routeParams,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  columns: PaginatedResult<Column> | undefined;
  dataTypes: PaginatedResult<DataType> | undefined;
  location: URL;
  query: URLSearchParams;
  includeAutogenerated: boolean;
  routeParams: Record<string, string>;
  dispatch: AppDispatch;
}) => {
  const { columnID } = routeParams;
  const { pathname } = location;
  const isCreatePage = pathname.indexOf('create') > -1;

  useEffect(() => {
    if (selectedTenantID) {
      if (isCreatePage) {
        dispatch(fetchUserStoreColumnSuccess(blankColumn()));
      } else if (columnID) {
        const matchingColumn = columns?.data.find(
          (col: Column) => col.id === columnID
        );
        if (matchingColumn) {
          dispatch(changeSelectedColumn(matchingColumn));
        } else {
          dispatch(fetchColumn(selectedTenantID, columnID));
        }
        dispatch(
          fetchRetentionDurationsForColumn(
            selectedTenantID,
            columnID,
            DurationType.SoftDeleted
          )
        );
      }
      dispatch(fetchPurposes(selectedTenantID, new URLSearchParams()));
    }
  }, [selectedTenantID, columnID, columns, query, isCreatePage, dispatch]);
  useEffect(() => {
    if (selectedTenantID && !isCreatePage) {
      dispatch(
        fetchAccessorsForColumn(
          selectedTenantID,
          columnID,
          query,
          includeAutogenerated
        )
      );
    }
  }, [
    selectedTenantID,
    columnID,
    query,
    isCreatePage,
    includeAutogenerated,
    dispatch,
  ]);
  useEffect(() => {
    if (selectedTenantID) {
      const params = new URLSearchParams();
      params.set('data_types_limit', String(MAX_LIMIT));
      dispatch(fetchDataTypes(selectedTenantID, params));
    }
  }, [selectedTenantID, dispatch]);

  return (
    <>
      {dataTypes && (
        <ConnectedColumnDetails
          isCreatePage={isCreatePage}
          dataTypes={dataTypes.data}
        />
      )}
      {!isCreatePage && <ConnectedAccessorList readOnly />}
      {!isCreatePage && <ConnectedPurposeSettings />}
    </>
  );
};

export default connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  dataTypes: state.dataTypes,
  columns: state.userStoreDisplayColumns,
  location: state.location,
  query: state.query,
  includeAutogenerated: state.accessorListIncludeAutogenerated,
  routeParams: state.routeParams,
}))(ColumnDetailPage);
