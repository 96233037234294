import { URLSearchParams } from 'url';

export const DEFAULT_PAGE_LIMIT = 50;
export const MAX_LIMIT = 1500;

const columns = ['filter', 'starting_after', 'ending_before', 'limit'];

export const getParamsAsObject = (
  prefix: string,
  params: URLSearchParams
): Record<string, string> => {
  if (!params) {
    return {};
  }
  const paramsAsObject = columns
    .map((element) => prefix + element)
    .reduce((acc: Record<string, string>, paramName: string) => {
      if (params.has(paramName)) {
        acc[paramName.substring(prefix.length)] = params.get(
          paramName
        ) as string;
      }
      return acc;
    }, {});
  return paramsAsObject;
};
