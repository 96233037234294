import {
  HTTPError,
  extractErrorMessage,
  makeAPIError,
  tryGetJSON,
} from '@userclouds/sharedui';

import { makeCompanyConfigURL } from '../API';
import { countQueryToJSON, type CountQuery } from '../models/CountQuery';
import { CountMetric } from '../models/Metrics';
import ChartQuery from '../chart/ChartQuery';
import ChartData from '../chart/ChartData';

export const fetchCountData = async (
  tenantID: string,
  query: CountQuery
): Promise<CountMetric[]> => {
  return new Promise(async (resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${encodeURIComponent(tenantID)}/counters/query`
    );

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(countQueryToJSON(query)),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }

        resolve(response.json());
      })
      .catch((error) => {
        reject(
          makeAPIError(error, `error fetching data (${JSON.stringify(query)})`)
        );
      });
  });
};

export const fetchChartData = async (
  tenantID: string,
  queries: ChartQuery[]
): Promise<ChartData> => {
  return new Promise(async (resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${encodeURIComponent(tenantID)}/counters/charts`
    );

    try {
      const rawResponse = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(queries),
      });
      const jsonResponse = await tryGetJSON(rawResponse);
      resolve(ChartData.fromJSON(jsonResponse));
    } catch (e) {
      reject(
        makeAPIError(e, `error fetching data (${JSON.stringify(queries)})`)
      );
    }
  });
};
