import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  ButtonGroup,
  Card,
  CardRow,
  InputReadOnly,
  Label,
  LoaderDots,
  Select,
  IconFacebookBlackAndWhite,
  IconGoogleBlackAndWhite,
  IconLinkedInBlackAndWhite,
  IconMicrosoftInBlackAndWhite,
  IconLogin,
  InlineNotification,
  Table,
  TableRow,
  TableRowHead,
  TableBody,
  TableCell,
  TableHead,
  Text,
  TextInput,
} from '@userclouds/ui-component-lib';
import { APIError } from '@userclouds/sharedui';

import { makeCleanPageLink } from '../AppNavigation';
import {
  modifyPlexConfig,
  updatePlexConfigRequest,
  updatePlexConfigSuccess,
  updatePlexConfigError,
  modifyTelephonyProvider,
  getBlankOIDCProvider,
} from '../actions/authn';
import {
  getTenantKeysRequest,
  getTenantKeysSuccess,
  getTenantKeysError,
  rotateTenantKeysRequest,
  rotateTenantKeysSuccess,
  rotateTenantKeysError,
} from '../actions/keys';
import { AppDispatch, RootState } from '../store';
import TenantPlexConfig, {
  addProvider,
  UpdatePlexConfigReason,
} from '../models/TenantPlexConfig';
import LoginApp from '../models/LoginApp';
import Provider, { ProviderType } from '../models/Provider';
import Tenant, { SelectedTenant } from '../models/Tenant';
import { TwilioPropertyType } from '../models/TelephonyProvider';
import { OIDCProviderType } from '../models/OIDCProvider';
import { addLoginAppToTenant } from '../API/authn';
import { fetchTenantPublicKeys, rotateTenantKeys } from '../API/TenantKeys';
import { fetchPlexConfig, savePlexConfig } from '../thunks/authn';
import { RandomBase64, RandomHex } from '../util/Rand';
import Link from '../controls/Link';
import { PageTitle } from '../mainlayout/PageWrap';
import Styles from './AuthNPage.module.css';
import PageCommon from './PageCommon.module.css';
import Breadcrumbs from '../controls/Breadcrumbs';

const PlexApps = ({
  apps,
  employeeApp,
  selectedTenant,
  query,
}: {
  apps: LoginApp[];
  employeeApp: LoginApp;
  selectedTenant: SelectedTenant | undefined;
  query: URLSearchParams;
}) => {
  const cleanQuery = makeCleanPageLink(query);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableRowHead>Name</TableRowHead>
          <TableRowHead>Grant types</TableRowHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {apps.map((app) => (
          <TableRow key={app.id}>
            <TableCell>
              {selectedTenant?.is_admin ? (
                <Link href={`/authn/plex_app/${app.id}${cleanQuery}`}>
                  {app.name}
                </Link>
              ) : (
                <Text>{app.name}</Text>
              )}
            </TableCell>
            <TableCell>
              {app && app.grant_types && app.grant_types.join(', ')}
            </TableCell>
          </TableRow>
        ))}
        {employeeApp.id && (
          <TableRow key={employeeApp.id}>
            <TableCell>
              {selectedTenant?.is_admin ? (
                <Link href={`/authn/plex_employee_app${cleanQuery}`}>
                  Employee App
                </Link>
              ) : (
                <Text>Employee App</Text>
              )}
            </TableCell>
            <TableCell>
              {employeeApp.grant_types && employeeApp.grant_types.join(', ')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const LoginProviders = ({
  modifiedProviders,
  savedProviders,
  query,
}: {
  modifiedProviders: Provider[];
  savedProviders: Provider[];
  query: URLSearchParams;
}) => {
  const cleanQuery = makeCleanPageLink(query);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableRowHead>Identity Provider</TableRowHead>
          <TableRowHead>Type</TableRowHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {modifiedProviders.map((provider) => (
          <TableRow key={provider.id}>
            <TableCell>
              {savedProviders.find((p: Provider) => p.id === provider.id) ? (
                <Link href={`/authn/plex_provider/${provider.id}${cleanQuery}`}>
                  {provider.name}
                </Link>
              ) : (
                provider.name
              )}
            </TableCell>
            <TableCell>
              {provider.type === ProviderType.uc
                ? 'UserClouds'
                : provider.type === ProviderType.auth0
                  ? 'Auth0'
                  : 'Cognito'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const onCreateAppClick =
  (tenantID: string) => async (dispatch: AppDispatch) => {
    const newAppID = uuidv4();
    const newName = 'New Plex App';
    const newClientID = RandomHex(32);
    const newClientSecret = RandomBase64(64);

    dispatch(updatePlexConfigRequest());

    addLoginAppToTenant(
      tenantID,
      newAppID,
      newName,
      newClientID,
      newClientSecret
    ).then(
      (tenantPlex: TenantPlexConfig) => {
        dispatch(
          updatePlexConfigSuccess(tenantPlex, UpdatePlexConfigReason.AddApp)
        );
      },
      (error: APIError) => {
        dispatch(updatePlexConfigError(error));
      }
    );
  };

const onAddProviderClick =
  (tenant: Tenant, modifiedConfig: TenantPlexConfig) =>
  async (dispatch: AppDispatch) => {
    const newProviderID = uuidv4();
    dispatch(
      modifyPlexConfig(
        addProvider(modifiedConfig, {
          id: newProviderID,
          name: 'New Plex Provider',
          type: ProviderType.uc,
          uc: {
            idp_url: tenant.tenant_url ? tenant.tenant_url : 'abc',
            apps: [],
          },
        })
      )
    );
  };

const getProviderIcon = (providerType: string) => {
  if (providerType === OIDCProviderType.Custom) {
    return <IconLogin />;
  }
  if (providerType === OIDCProviderType.Facebook) {
    return <IconFacebookBlackAndWhite />;
  }
  if (providerType === OIDCProviderType.Google) {
    return <IconGoogleBlackAndWhite />;
  }
  if (providerType === OIDCProviderType.LinkedIn) {
    return <IconLinkedInBlackAndWhite />;
  }
  if (providerType === OIDCProviderType.Microsoft) {
    return <IconMicrosoftInBlackAndWhite />;
  }
};

const AuthNHome = ({
  selectedTenant,
  plexConfig,
  modifiedConfig,
  isDirty,
  fetchError,
  isSaving,
  saveSuccess,
  saveError,
  query,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  plexConfig: TenantPlexConfig | undefined;
  modifiedConfig: TenantPlexConfig | undefined;
  isDirty: boolean;
  fetchError: string;
  isSaving: boolean;
  saveSuccess: UpdatePlexConfigReason | undefined;
  saveError: string;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  if (fetchError || !plexConfig || !modifiedConfig) {
    return <Text>{fetchError || 'Loading...'}</Text>;
  }

  const readOnly = !selectedTenant?.is_admin;

  return (
    <>
      <Card
        title="Login Applications"
        description="Create different login experiences, user-facing emails and security
        requirements for different user groups. A UserClouds application
        corresponds to a single OAuth2 client application."
        lockedMessage={readOnly ? 'You do not have edit access' : ''}
      >
        <PlexApps
          apps={modifiedConfig.tenant_config.plex_map.apps}
          employeeApp={modifiedConfig.tenant_config.plex_map.employee_app}
          selectedTenant={selectedTenant}
          query={query}
        />
        {selectedTenant?.is_admin && (
          <Button
            theme="secondary"
            onClick={() => {
              dispatch(onCreateAppClick(selectedTenant?.id || ''));
            }}
          >
            Create app
          </Button>
        )}
      </Card>

      <Card
        title="OAuth Connections"
        lockedMessage={readOnly ? 'You do not have edit access' : ''}
        description="Configure Social and other 3rd party OIDC Identity Providers for Plex."
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableRowHead key="type_headertype">Type</TableRowHead>
              <TableRowHead key="type_headerdesc">Provider Name</TableRowHead>
              <TableRowHead key="type_headerurl">Provider URL</TableRowHead>
              <TableRowHead key="type_headerconfigured">
                Configured
              </TableRowHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {modifiedConfig.tenant_config.oidc_providers.providers.map(
              (provider) => (
                <TableRow key={provider.name}>
                  <TableCell>{getProviderIcon(provider.type)}</TableCell>
                  <TableCell>
                    {!readOnly ? (
                      <Link
                        key={provider.name + provider.issuer_url}
                        href={
                          `/authn/oidc_provider/${provider.name}` +
                          makeCleanPageLink(query)
                        }
                      >
                        {provider.name}
                      </Link>
                    ) : (
                      provider.name
                    )}
                  </TableCell>
                  <TableCell>{provider.issuer_url}</TableCell>
                  <TableCell>
                    {provider.client_id && provider.client_secret
                      ? 'Yes'
                      : 'No'}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        {selectedTenant?.is_admin && (
          <Link
            href={'/authn/oidc_provider/create' + makeCleanPageLink(query)}
            applyStyles={false}
          >
            <Button
              theme="secondary"
              onClick={() => {
                dispatch(getBlankOIDCProvider());
              }}
            >
              Add New Provider
            </Button>
          </Link>
        )}
      </Card>

      <Card
        title="Identity Providers: Migrations & Back-Ups"
        description="Connect UserClouds to a pre-existing Identity Provider like Auth0 or
        Cognito. UserClouds can automatically migrate from your existing Auth0
        User Database or run two platforms simultaneously to minimize
        downtime."
      >
        <LoginProviders
          modifiedProviders={modifiedConfig.tenant_config.plex_map.providers}
          savedProviders={plexConfig.tenant_config.plex_map.providers}
          query={query}
        />
        {!readOnly && (
          <Button
            theme="secondary"
            onClick={() => {
              dispatch(onAddProviderClick(selectedTenant, modifiedConfig));
            }}
          >
            Add provider
          </Button>
        )}
        <Label>
          <div>Active Provider:</div>

          {readOnly ? (
            <InputReadOnly>
              {
                modifiedConfig.tenant_config.plex_map.providers.find(
                  (p) =>
                    p.id ===
                    modifiedConfig.tenant_config.plex_map.policy
                      .active_provider_id
                )?.name
              }
            </InputReadOnly>
          ) : (
            <Select
              name="active_provider"
              defaultValue={
                modifiedConfig.tenant_config.plex_map.policy.active_provider_id
              }
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                modifiedConfig.tenant_config.plex_map.policy.active_provider_id =
                  e.target.value;
                dispatch(modifyPlexConfig(modifiedConfig));
              }}
            >
              {modifiedConfig.tenant_config.plex_map.providers.map(
                (provider) => (
                  <option key={`select-${provider.id}`} value={provider.id}>
                    {provider.name}
                  </option>
                )
              )}
            </Select>
          )}
        </Label>
        {saveError && (
          <InlineNotification theme="alert">{saveError}</InlineNotification>
        )}
        {saveSuccess === UpdatePlexConfigReason.AddProvider && (
          <InlineNotification theme="success">{saveSuccess}</InlineNotification>
        )}
        {selectedTenant?.is_admin && (
          <Button
            disabled={!isDirty}
            isLoading={isSaving}
            onClick={() => {
              dispatch(
                savePlexConfig(
                  selectedTenant.id,
                  modifiedConfig,
                  UpdatePlexConfigReason.AddProvider
                )
              );
            }}
          >
            Save providers
          </Button>
        )}
      </Card>

      <Card
        title="Telephony Provider Settings"
        lockedMessage={readOnly ? 'You do not have edit access' : ''}
        description="Configure telephony provider settings for sending SMS messages. Twilio is currently supported."
      >
        <CardRow>
          <Label className={Styles.clientDataElement}>
            Twilio Account SID
            <TextInput
              id="telephony_provider_twilio_account_sid"
              name="telephony_provider_twilio_account_sid"
              readOnly={readOnly}
              defaultValue={
                modifiedConfig.tenant_config.plex_map.telephony_provider
                  .properties[TwilioPropertyType.AccountSID]
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  modifyTelephonyProvider({
                    [TwilioPropertyType.AccountSID]: e.target.value,
                  })
                );
              }}
            />
          </Label>
          <Label className={Styles.clientDataElement}>
            Twilio Standard API Key SID
            <TextInput
              id="telephony_provider_twilio_api_key_sid"
              name="telephony_provider_twilio_api_key_sid"
              readOnly={readOnly}
              defaultValue={
                modifiedConfig.tenant_config.plex_map.telephony_provider
                  .properties[TwilioPropertyType.APIKeySID]
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  modifyTelephonyProvider({
                    [TwilioPropertyType.APIKeySID]: e.target.value,
                  })
                );
              }}
            />
          </Label>
          <Label className={Styles.clientDataElement}>
            Twilio Standard API Secret
            <TextInput
              id="telephony_provider_twilio_api_secret"
              name="telephony_provider_twilio_api_secret"
              readOnly={readOnly}
              defaultValue={
                modifiedConfig.tenant_config.plex_map.telephony_provider
                  .properties[TwilioPropertyType.APISecret]
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  modifyTelephonyProvider({
                    [TwilioPropertyType.APISecret]: e.target.value,
                  })
                );
              }}
            />
          </Label>
        </CardRow>
        {saveError && (
          <InlineNotification theme="alert">{saveError}</InlineNotification>
        )}
        {saveSuccess === UpdatePlexConfigReason.ModifyTelephonyProvider && (
          <InlineNotification theme="success">{saveSuccess}</InlineNotification>
        )}
        {selectedTenant?.is_admin && (
          <Button
            disabled={!isDirty}
            isLoading={isSaving}
            onClick={() => {
              dispatch(
                savePlexConfig(
                  selectedTenant.id,
                  modifiedConfig,
                  UpdatePlexConfigReason.ModifyTelephonyProvider
                )
              );
            }}
          >
            Save
          </Button>
        )}
      </Card>

      <Card
        title="Email Settings"
        lockedMessage={readOnly ? 'You do not have edit access' : ''}
        description="Configure an email server integration."
      >
        <Label className={Styles.urlInput}>
          <div className={Styles.clientDataRow}>
            <Label className={Styles.clientDataElement}>
              Email Server Host (optional)
              <TextInput
                id="email_host"
                name="email_host"
                readOnly={readOnly}
                defaultValue={modifiedConfig.tenant_config.plex_map.email_host}
                onChange={(e: React.ChangeEvent) => {
                  modifiedConfig.tenant_config.plex_map.email_host = (
                    e.target as HTMLInputElement
                  ).value;
                  dispatch(modifyPlexConfig(modifiedConfig));
                }}
              />
            </Label>
            <Label className={Styles.clientDataElement}>
              Port
              <TextInput
                id="email_port"
                name="email_port"
                readOnly={readOnly}
                defaultValue={modifiedConfig.tenant_config.plex_map.email_port}
                type="number"
                onChange={(e: React.ChangeEvent) => {
                  modifiedConfig.tenant_config.plex_map.email_port = (
                    e.target as HTMLInputElement
                  ).valueAsNumber;
                  dispatch(modifyPlexConfig(modifiedConfig));
                }}
              />
            </Label>
            <Label className={Styles.clientDataElement}>
              Username
              <TextInput
                id="email_username"
                name="email_username"
                readOnly={readOnly}
                defaultValue={
                  modifiedConfig.tenant_config.plex_map.email_username
                }
                onChange={(e: React.ChangeEvent) => {
                  modifiedConfig.tenant_config.plex_map.email_username = (
                    e.target as HTMLInputElement
                  ).value;
                  dispatch(modifyPlexConfig(modifiedConfig));
                }}
              />
            </Label>
            <Label className={Styles.clientDataElement}>
              Password
              <TextInput
                id="email_password"
                name="email_password"
                readOnly={readOnly}
                defaultValue={
                  modifiedConfig.tenant_config.plex_map.email_password
                }
                type="password"
                onChange={(e: React.ChangeEvent) => {
                  modifiedConfig.tenant_config.plex_map.email_password = (
                    e.target as HTMLInputElement
                  ).value;
                  dispatch(modifyPlexConfig(modifiedConfig));
                }}
              />
            </Label>
          </div>
          {saveError && (
            <InlineNotification theme="alert">{saveError}</InlineNotification>
          )}
          {saveSuccess === UpdatePlexConfigReason.ModifyEmailServer && (
            <InlineNotification theme="success">
              {saveSuccess}
            </InlineNotification>
          )}
          {selectedTenant?.is_admin && (
            <Button
              disabled={!isDirty}
              isLoading={isSaving}
              onClick={() => {
                dispatch(
                  savePlexConfig(
                    selectedTenant.id,
                    modifiedConfig,
                    UpdatePlexConfigReason.ModifyEmailServer
                  )
                );
              }}
            >
              Save email settings
            </Button>
          )}
        </Label>
      </Card>
      <ConnectedJWTKeys />
    </>
  );
};
const ConnectedAuthNHome = connect((state: RootState) => {
  return {
    selectedTenant: state.selectedTenant,
    plexConfig: state.tenantPlexConfig,
    modifiedConfig: state.modifiedPlexConfig,
    isDirty: state.plexConfigIsDirty,
    fetchError: state.fetchPlexConfigError,
    isSaving: state.savingPlexConfig,
    saveSuccess: state.savePlexConfigSuccess,
    saveError: state.savePlexConfigError,
    query: state.query,
  };
})(AuthNHome);

const fetchKeys = (tenantID: string) => (dispatch: AppDispatch) => {
  dispatch(getTenantKeysRequest());
  fetchTenantPublicKeys(tenantID).then(
    (response: string[]) => {
      dispatch(getTenantKeysSuccess(response));
    },
    (error: APIError) => {
      dispatch(getTenantKeysError(error));
    }
  );
};

const rotateKeys = (tenantID: string) => (dispatch: AppDispatch) => {
  dispatch(rotateTenantKeysRequest());
  rotateTenantKeys(tenantID).then(
    () => {
      dispatch(rotateTenantKeysSuccess());
      dispatch(fetchKeys(tenantID));
    },
    (error: APIError) => {
      dispatch(rotateTenantKeysError(error));
    }
  );
};

const JWTKeys = ({
  selectedTenant,
  tenantPublicKey,
  fetchingKeys,
  rotatingKeys,
  fetchError,
  rotateError,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  tenantPublicKey: string;
  fetchingKeys: boolean;
  rotatingKeys: boolean;
  fetchError: string;
  rotateError: string;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenant) {
      // TODO: fetch keys only once card is open
      // need to expose the internal open/closed state of cards
      dispatch(fetchKeys(selectedTenant.id));
    }
  }, [selectedTenant, dispatch]);

  return (
    <Card
      title="JWT Signing Keys"
      description="Copy, download and rotate your public and private signing keys for this tenant."
      isClosed
    >
      {selectedTenant && tenantPublicKey ? (
        <>
          <Label>
            Public Key
            <InputReadOnly monospace className={Styles.publicKey}>
              {tenantPublicKey}
            </InputReadOnly>
          </Label>
          {selectedTenant.is_admin && (
            <>
              {rotateError && (
                <InlineNotification theme="alert">
                  {rotateError}
                </InlineNotification>
              )}
              <ButtonGroup>
                <Button
                  theme="primary"
                  isLoading={rotatingKeys}
                  onClick={() => {
                    dispatch(rotateKeys(selectedTenant.id));
                  }}
                >
                  Rotate Keys
                </Button>
                <Button theme="outline" disabled={rotatingKeys}>
                  <a
                    href={`/api/tenants/${selectedTenant.id}/keys/private`}
                    title=""
                  >
                    Download Private Key
                  </a>
                </Button>
              </ButtonGroup>
            </>
          )}
        </>
      ) : fetchingKeys ? (
        <LoaderDots assistiveText="Loading ..." size="medium" theme="brand" />
      ) : (
        ''
      )}
      {fetchError && (
        <InlineNotification theme="alert">{fetchError}</InlineNotification>
      )}
    </Card>
  );
};
const ConnectedJWTKeys = connect((state: RootState) => ({
  selectedTenant: state.selectedTenant,
  tenantPublicKey: state.tenantPublicKey,
  fetchingKeys: state.fetchingPublicKeys,
  rotatingKeys: state.rotatingTenantKeys,
  fetchError: state.fetchTenantPublicKeysError,
  rotateError: state.rotateTenantKeysError,
}))(JWTKeys);

const AuthNPage = ({
  tenantID,
  query,
  dispatch,
}: {
  tenantID: string | undefined;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (tenantID) {
      dispatch(fetchPlexConfig(tenantID));
    }
  }, [tenantID, query, dispatch]);

  return (
    <>
      <Breadcrumbs />
      <PageTitle
        title="Authentication"
        description={
          <>
            {
              'Create a world-class login and sign up experience for your software. '
            }
            <a
              href="https://docs.userclouds.com/docs/introduction-1"
              title="UserClouds documentation for key concepts in authentication"
              target="new"
              className={PageCommon.link}
            >
              Learn more here.
            </a>
          </>
        }
      />
      <ConnectedAuthNHome />
    </>
  );
};

export default connect((state: RootState) => {
  return {
    tenantID: state.selectedTenantID,
    query: state.query,
  };
})(AuthNPage);
