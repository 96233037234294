import { v4 as uuidv4 } from 'uuid';

import { APIError, makeAPIError } from '@userclouds/sharedui';

import { redirect } from '../routing';
import { RootState, AppDispatch } from '../store';
import {
  createTenantAccessor,
  fetchTenantAccessor,
  fetchTenantAccessors,
  updateTenantAccessor,
  executeTenantAccessor,
} from '../API/accessors';
import {
  getAccessorRequest,
  getAccessorSuccess,
  getAccessorError,
  updateAccessorDetailsRequest,
  updateAccessorDetailsSuccess,
  updateAccessorDetailsError,
  saveAccessorColumnsConfigurationRequest,
  saveAccessorColumnsConfigurationSuccess,
  saveAccessorColumnsConfigurationError,
  createAccessorRequest,
  createAccessorSuccess,
  createAccessorError,
  getTenantAccessorsRequest,
  getTenantAccessorsSuccess,
  getTenantAccessorsError,
  executeAccessorSuccess,
  executeAccessorError,
  executeAccessorReset,
  getAccessorMetricsRequest,
  getAccessorMetricsSuccess,
  getAccessorMetricsError,
} from '../actions/accessors';
import {
  getAccessorsForColumnRequest,
  getAccessorsForColumnSuccess,
  getAccessorsForColumnError,
} from '../actions/userstore';
import { postSuccessToast } from './notifications';
import { modifyAccessPolicy } from '../actions/tokenizer';
import PaginatedResult from '../models/PaginatedResult';
import Accessor, { AccessorSavePayload } from '../models/Accessor';
import { PAGINATION_API_VERSION } from '../API';
import { fetchCountData } from '../API/metrics';
import type { CountQuery } from '../models/CountQuery';
import type { CountMetric } from '../models/Metrics';

const PAGINATION_LIMIT = '50';

export const createAccessor =
  (
    selectedTenantID: string,
    selectedCompanyID: string,
    accessorToCreate: AccessorSavePayload
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(createAccessorRequest());
    createTenantAccessor(selectedTenantID, accessorToCreate).then(
      (response: Accessor) => {
        dispatch(createAccessorSuccess(response));
        dispatch(postSuccessToast('Successfully created accessor'));
        redirect(
          `/accessors/${response.id}/latest?company_id=${
            selectedCompanyID as string
          }&tenant_id=${selectedTenantID}`
        );
      },
      (error: APIError) => {
        dispatch(createAccessorError(error));
        dispatch(
          modifyAccessPolicy({
            id: uuidv4(),
          })
        );
        document.getElementById('aboutAccessor')?.scrollIntoView();
      }
    );
  };

export const handleCreateAccessor =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      selectedCompanyID,
      selectedTenantID,
      accessorToCreate,
      modifiedAccessPolicy,
      modifiedTokenAccessPolicy,
    } = getState();

    if (selectedTenantID && selectedCompanyID) {
      accessorToCreate.composed_access_policy = modifiedAccessPolicy;
      accessorToCreate.composed_token_access_policy = modifiedTokenAccessPolicy;
      dispatch(
        createAccessor(selectedTenantID, selectedCompanyID, accessorToCreate)
      );
    }
  };

export const fetchAccessors =
  (
    tenantID: string,
    params: URLSearchParams,
    includeAutogenerated: boolean,
    withMetrics?: boolean
  ) =>
  (dispatch: AppDispatch) => {
    const paramsAsObject = [
      'accessors_starting_after',
      'accessors_ending_before',
      'accessors_limit',
      'accessors_filter',
    ].reduce((acc: Record<string, string>, paramName: string) => {
      if (params.has(paramName)) {
        acc[paramName.substring(10)] = params.get(paramName) as string;
      }
      return acc;
    }, {});
    if (!paramsAsObject.limit) {
      paramsAsObject.limit = PAGINATION_LIMIT;
    }
    if (!paramsAsObject.sort_order) {
      paramsAsObject.sort_order = 'ascending';
    }
    if (!paramsAsObject.sort_key) {
      paramsAsObject.sort_key = 'name,id';
    }
    if (!paramsAsObject.version) {
      paramsAsObject.version = PAGINATION_API_VERSION;
    }
    if (!includeAutogenerated) {
      if (!paramsAsObject.filter) {
        paramsAsObject.filter = "('is_autogenerated',EQ,'false')";
      } else {
        paramsAsObject.filter = `(${paramsAsObject.filter},AND,('is_autogenerated',EQ,'false'))`;
      }
    }
    dispatch(getTenantAccessorsRequest());
    return fetchTenantAccessors(tenantID, paramsAsObject).then(
      (accessors: PaginatedResult<Accessor>) => {
        dispatch(getTenantAccessorsSuccess(accessors));
        if (withMetrics) {
          dispatch(
            fetchAccessorMetrics(
              tenantID,
              accessors.data.map((accessor) => accessor.id)
            )
          );
        }
      },
      (error: APIError) => {
        dispatch(getTenantAccessorsError(error));
      }
    );
  };

export const fetchAccessorMetrics =
  (tenantID: string, accessors: string[]) => (dispatch: AppDispatch) => {
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const query: CountQuery = {
      tenantID: tenantID,
      service: 'idp',
      objectIds: accessors,
      start: oneMonthAgo.toISOString(),
      end: now.toISOString(),
      eventSuffixFilter: ['succ'], // Only fetch successful events
    };

    dispatch(getAccessorMetricsRequest());
    return fetchCountData(tenantID, query).then(
      (accessorMetrics: CountMetric[]) => {
        dispatch(getAccessorMetricsSuccess(accessorMetrics));
      },
      (error: APIError) => {
        dispatch(getAccessorMetricsError(error));
      }
    );
  };

export const fetchAccessorsForColumn =
  (
    tenantID: string,
    columnID: string,
    params: URLSearchParams,
    includeAutogenerated: boolean
  ) =>
  (dispatch: AppDispatch) => {
    const paramsAsObject = [
      'accessors_starting_after',
      'accessors_ending_before',
      'accessors_limit',
    ].reduce((acc: Record<string, string>, paramName: string) => {
      if (params.has(paramName)) {
        acc[paramName.substring(10)] = params.get(paramName) as string;
      }
      return acc;
    }, {});
    if (!paramsAsObject.limit) {
      paramsAsObject.limit = PAGINATION_LIMIT;
    }
    // TODO: we may need to merge with other filter queries eventually
    paramsAsObject.filter = `('column_ids',HAS,'${columnID}')`;
    if (!includeAutogenerated) {
      paramsAsObject.filter = `(${paramsAsObject.filter},AND,('is_autogenerated',EQ,'false'))`;
    }

    dispatch(getAccessorsForColumnRequest());
    return fetchTenantAccessors(tenantID, paramsAsObject).then(
      (accessors: PaginatedResult<Accessor>) => {
        dispatch(getAccessorsForColumnSuccess(accessors));
      },
      (error: APIError) => {
        dispatch(getAccessorsForColumnError(error));
      }
    );
  };

export const fetchAccessor =
  (tenantID: string, accessorID: string, version: string) =>
  (dispatch: AppDispatch) => {
    dispatch(getAccessorRequest(accessorID));
    return fetchTenantAccessor(
      tenantID,
      accessorID,
      !isNaN(parseInt(version, 10)) ? version : undefined
    ).then(
      (accessor: Accessor) => {
        dispatch(getAccessorSuccess(accessor));
      },
      (error: APIError) => {
        dispatch(getAccessorError(error));
      }
    );
  };

export const saveAccessorDetailsAndConfiguration =
  (tenantID: string, accessorToUpdate: Accessor) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      selectedCompanyID,
      modifiedAccessPolicy,
      modifiedTokenAccessPolicy,
      modifiedAccessor,
      savingAccessor,
    } = getState();

    // Ensure conditions from both original functions are met
    if (modifiedAccessor && !savingAccessor) {
      const accessor: AccessorSavePayload = {
        id: accessorToUpdate.id,
        name: accessorToUpdate.name?.trim(),
        description: accessorToUpdate.description?.trim(),
        data_life_cycle_state: accessorToUpdate.data_life_cycle_state,
        columns: accessorToUpdate.columns,
        access_policy_id: accessorToUpdate.access_policy.id,
        selector_config: accessorToUpdate.selector_config,
        purposes: accessorToUpdate.purposes,
        is_audit_logged: accessorToUpdate.is_audit_logged,
        are_column_access_policies_overridden:
          accessorToUpdate.are_column_access_policies_overridden,
        use_search_index: accessorToUpdate.use_search_index,
        composed_access_policy: modifiedAccessPolicy, // from second method
        composed_token_access_policy: modifiedTokenAccessPolicy, // from second method
      };

      // Initiating save request for details
      dispatch(updateAccessorDetailsRequest());

      // Using updateTenantAccessor to save details, which covers both functionalities
      return updateTenantAccessor(tenantID, accessor).then(
        (resp: Accessor) => {
          // Success actions for details
          dispatch(updateAccessorDetailsSuccess(resp));
          dispatch(postSuccessToast('Successfully saved accessor'));

          // Fetch the latest accessor data after update
          dispatch(fetchAccessor(tenantID, accessor.id, String(resp.version)));

          // Navigation logic based on feature flag
          redirect(
            `/accessors/${resp.id}/${resp.version}?company_id=${
              selectedCompanyID as string
            }&tenant_id=${tenantID}`
          );
        },
        (error: APIError) => {
          // Error actions for both functionalities
          dispatch(updateAccessorDetailsError(error));
        }
      );
    }
  };

export const saveAccessorDetails =
  (tenantID: string, accessorToUpdate: Accessor) =>
  (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const { selectedCompanyID } = getState();

    const accessor: AccessorSavePayload = {
      id: accessorToUpdate.id,
      name: accessorToUpdate.name?.trim(),
      description: accessorToUpdate.description?.trim(),
      data_life_cycle_state: accessorToUpdate.data_life_cycle_state,
      columns: accessorToUpdate.columns,
      access_policy_id: accessorToUpdate.access_policy.id,
      selector_config: accessorToUpdate.selector_config,
      purposes: accessorToUpdate.purposes,
      is_audit_logged: accessorToUpdate.is_audit_logged,
      are_column_access_policies_overridden:
        accessorToUpdate.are_column_access_policies_overridden,
      use_search_index: accessorToUpdate.use_search_index,
    };

    dispatch(updateAccessorDetailsRequest());
    return updateTenantAccessor(tenantID, accessor).then(
      (resp: Accessor) => {
        dispatch(updateAccessorDetailsSuccess(resp));
        dispatch(postSuccessToast('Successfully saved accessor'));
        redirect(
          `/accessors/${resp.id}/${resp.version}?company_id=${
            selectedCompanyID as string
          }&tenant_id=${tenantID}`
        );
      },
      (error: APIError) => {
        dispatch(updateAccessorDetailsError(error));
      }
    );
  };

export const saveAccessorColumnConfiguration =
  (tenantID: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      selectedCompanyID,
      modifiedAccessPolicy,
      modifiedTokenAccessPolicy,
      modifiedAccessor,
      savingAccessor,
    } = getState();

    if (modifiedAccessor && !savingAccessor) {
      const accessor: AccessorSavePayload = {
        id: modifiedAccessor.id,
        name: modifiedAccessor.name,
        description: modifiedAccessor.description,
        data_life_cycle_state: modifiedAccessor.data_life_cycle_state,
        columns: modifiedAccessor.columns,
        access_policy_id: modifiedAccessor.access_policy.id,
        selector_config: modifiedAccessor.selector_config,
        purposes: modifiedAccessor.purposes,
        is_audit_logged: modifiedAccessor.is_audit_logged,
        are_column_access_policies_overridden:
          modifiedAccessor.are_column_access_policies_overridden,
        use_search_index: modifiedAccessor.use_search_index,
      };

      dispatch(saveAccessorColumnsConfigurationRequest());
      accessor.composed_access_policy = modifiedAccessPolicy;
      accessor.composed_token_access_policy = modifiedTokenAccessPolicy;
      return updateTenantAccessor(tenantID, accessor).then(
        (resp: Accessor) => {
          dispatch(saveAccessorColumnsConfigurationSuccess());
          dispatch(
            postSuccessToast(
              'Successfully updated accessor column configuration'
            )
          );
          dispatch(fetchAccessor(tenantID, accessor.id, String(resp.version)));
          redirect(
            `/accessors/${resp.id}/${resp.version}?company_id=${
              selectedCompanyID as string
            }&tenant_id=${tenantID}`
          );
        },
        (error: APIError) => {
          dispatch(saveAccessorColumnsConfigurationError(error));
        }
      );
    }
  };

export const executeAccessor =
  (
    tenantID: string,
    accessorID: string,
    context: string,
    selectorValues: string,
    piiFields: string[],
    sensitiveFields: string[],
    pageSize: number
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(executeAccessorReset());
      let contextJSON;
      try {
        contextJSON = JSON.parse(context);
      } catch {
        throw new Error('Context must be a valid JSON object');
      }
      if (typeof contextJSON !== 'object') {
        throw new Error('Context must be a valid JSON object');
      }
      let selectorValuesJSON;
      try {
        selectorValuesJSON = JSON.parse(selectorValues);
      } catch {
        throw new Error('Selector values must be a valid JSON array');
      }
      if (!Array.isArray(selectorValuesJSON)) {
        throw new Error('Selector values must be a valid JSON array');
      }
      return executeTenantAccessor(
        tenantID,
        accessorID,
        contextJSON,
        selectorValuesJSON,
        pageSize
      ).then(
        (resp) => {
          dispatch(executeAccessorSuccess(resp, piiFields, sensitiveFields));
        },
        (error: APIError) => {
          dispatch(executeAccessorError(error));
        }
      );
    } catch (error) {
      dispatch(executeAccessorError(makeAPIError(error)));
    }
  };
