import {
  APIError,
  HTTPError,
  extractErrorMessage,
  makeAPIError,
  tryGetJSON,
} from '@userclouds/sharedui';

import { PAGINATION_API_VERSION, makeCompanyConfigURL } from '../API';
import { MAX_LIMIT } from '../controls/PaginationHelper';
import PermissionsOnObject from '../models/authz/Permissions';
import PaginatedResult from '../models/PaginatedResult';
import AccessPolicy, {
  AccessPolicyTemplate,
  AccessPolicyTestResult,
} from '../models/AccessPolicy';
import AccessPolicyContext from '../models/AccessPolicyContext';
import Transformer, { TransformerTestResult } from '../models/Transformer';
import { mergeFilter } from '../controls/SearchHelper';

export const testRunAccessPolicy = async (
  tenantID: string,
  policy: AccessPolicy,
  context: AccessPolicyContext
): Promise<AccessPolicyTestResult | APIError> => {
  try {
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/access/actions/test'
    );
    const req = {
      access_policy: policy,
      context: context,
    };
    const rawResponse = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(req),
    });
    const jsonResponse = await tryGetJSON(rawResponse);
    const typedResponse = jsonResponse as AccessPolicyTestResult;
    return typedResponse;
  } catch (e) {
    return makeAPIError(e, 'error running policy');
  }
};

export const testRunAccessPolicyTemplate = async (
  tenantID: string,
  template: AccessPolicyTemplate,
  context: AccessPolicyContext,
  params: string
): Promise<AccessPolicyTestResult | APIError> => {
  try {
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/templates/actions/test'
    );
    const req = {
      access_policy_template: template,
      context,
      params,
    };
    const rawResponse = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(req),
    });
    const jsonResponse = await tryGetJSON(rawResponse);
    const typedResponse = jsonResponse as AccessPolicyTestResult;
    return typedResponse;
  } catch (e) {
    return makeAPIError(e, 'error running policy template');
  }
};

export const fetchAccessPolicies = async (
  tenantID: string,
  queryParams: Record<string, string>,
  includeAutogenerated: boolean
): Promise<PaginatedResult<AccessPolicy>> => {
  return new Promise(async (resolve, reject) => {
    if (!includeAutogenerated) {
      queryParams.filter = mergeFilter(
        { columnName: 'is_autogenerated', operator: 'EQ', value: 'False' },
        queryParams.filter
      );
    }
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/access',
      queryParams
    );
    fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching access policies'));
      });
  });
};

// This gets the latest version of an access policy by its ID
export const fetchTenantAccessPolicy = async (
  tenantID: string,
  policyID: string
): Promise<AccessPolicy> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${tenantID}/policies/access/${policyID}`
    );
    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching access policy'));
      });
  });
};

// This gets a specific version of an access policy
export const fetchAccessPolicyByVersion = async (
  tenantID: string,
  policyID: string,
  version: number
): Promise<AccessPolicy> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${tenantID}/policies/access/${policyID}?version=${version}`
    );
    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching access policy'));
      });
  });
};

export const createTenantAccessPolicy = async (
  tenantID: string,
  policy: AccessPolicy
): Promise<AccessPolicy> => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = makeCompanyConfigURL(
        '/api/tenants/' + tenantID + '/policies/access'
      );
      const rawResponse = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ access_policy: policy }),
      });
      const jsonResponse = await tryGetJSON(rawResponse);
      resolve(jsonResponse as AccessPolicy);
    } catch (e) {
      reject(makeAPIError(e, 'error creating policy'));
    }
  });
};

export const updateTenantAccessPolicy = async (
  tenantID: string,
  policy: AccessPolicy
): Promise<AccessPolicy> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/access/' + policy.id
    );
    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ access_policy: policy }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error updating access policy'));
      });
  });
};

export const deleteTenantAccessPolicy = async (
  tenantID: string,
  policyID: string,
  version: number
): Promise<void> => {
  return new Promise((resolve, reject) => {
    return fetch(
      `/api/tenants/${encodeURIComponent(
        tenantID
      )}/policies/access/${policyID}`,
      {
        method: 'DELETE',
        body: JSON.stringify({ version }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve();
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, `Error deleting access policy ${policyID}`));
      });
  });
};

export const fetchAccessPolicyTemplates = async (
  tenantID: string,
  queryParams: Record<string, any>
): Promise<PaginatedResult<AccessPolicyTemplate>> => {
  return new Promise(async (resolve, reject) => {
    if (!queryParams.version) {
      queryParams.version = PAGINATION_API_VERSION;
    }
    const url = makeCompanyConfigURL(
      `/api/tenants/${encodeURIComponent(tenantID)}/policies/templates`,
      queryParams
    );
    fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching access policy templates'));
      });
  });
};

// This gets the latest version of an access policy by its ID
export const fetchAccessPolicyTemplate = async (
  tenantID: string,
  policyID: string
): Promise<AccessPolicyTemplate> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${tenantID}/policies/templates/${policyID}`
    );
    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching access policy template'));
      });
  });
};

// This gets a specific version of an access policy
export const fetchAccessPolicyTemplateByVersion = async (
  tenantID: string,
  policyID: string,
  version: string
): Promise<AccessPolicyTemplate> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${tenantID}/policies/templates/${policyID}?version=${version}`
    );
    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching access policy template'));
      });
  });
};

export const createTenantAccessPolicyTemplate = async (
  tenantID: string,
  policyTemplate: AccessPolicyTemplate
): Promise<AccessPolicyTemplate> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${encodeURIComponent(tenantID)}/policies/templates`
    );
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ access_policy_template: policyTemplate }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }

        resolve(response.json());
      })
      .catch((error) => {
        reject(makeAPIError(error, `error creating policy template`));
      });
  });
};

export const updateAccessPolicyTemplate = async (
  tenantID: string,
  policyTemplate: AccessPolicyTemplate
): Promise<AccessPolicyTemplate> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${encodeURIComponent(
        tenantID
      )}/policies/templates/${encodeURIComponent(policyTemplate.id)}`
    );
    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ access_policy_template: policyTemplate }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }

        resolve(response.json());
      })
      .catch((error) => {
        reject(makeAPIError(error, `error updating policy template`));
      });
  });
};

export const deletePolicyTemplate = async (
  tenantID: string,
  templateID: string,
  version: number
): Promise<void> => {
  return new Promise((resolve, reject) => {
    return fetch(
      `/api/tenants/${encodeURIComponent(
        tenantID
      )}/policies/templates/${templateID}`,
      {
        method: 'DELETE',
        body: JSON.stringify({ version }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve();
      }, reject)
      .catch((e) => {
        reject(
          makeAPIError(e, `Error deleting access policy template ${templateID}`)
        );
      });
  });
};

export const createTenantTransformer = async (
  tenantID: string,
  transformer: Transformer
): Promise<Transformer> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/transformation'
    );
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ transformer: transformer }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error creating transformer'));
      });
  });
};

export const updateTenantTransformer = async (
  tenantID: string,
  policy: Transformer
): Promise<Transformer> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/transformation/' + policy.id
    );
    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify({ transformer: policy }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error updating transformer'));
      });
  });
};

export const fetchTenantTransformers = async (
  tenantID: string,
  queryParams: Record<string, string>
): Promise<PaginatedResult<Transformer>> => {
  return new Promise(async (resolve, reject) => {
    if (!queryParams.limit) {
      queryParams.limit = String(MAX_LIMIT);
    }
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/transformation',
      queryParams
    );
    fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const msg = await extractErrorMessage(response);
          throw new HTTPError(msg, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching transformers'));
      });
  });
};

export const fetchTenantTransformer = async (
  tenantID: string,
  policyID: string,
  version: number | undefined
): Promise<Transformer> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      `/api/tenants/${tenantID}/policies/transformation/${policyID}` +
        (version !== undefined ? `?version=${version}` : '')
    );
    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'Error fetching transformer'));
      });
  });
};

export const testRunTenantTransformer = (
  tenantID: string,
  transformer: Transformer,
  data: string
): Promise<TransformerTestResult> => {
  return new Promise((resolve, reject) => {
    const url = makeCompanyConfigURL(
      '/api/tenants/' + tenantID + '/policies/transformation/actions/test'
    );
    const req = {
      transformer: transformer,
      data: data,
    };
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(req),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e));
      });
  });
};

export const deleteTenantTransformer = async (
  tenantID: string,
  policyID: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    return fetch(
      `/api/tenants/${encodeURIComponent(
        tenantID
      )}/policies/transformation/${policyID}`,
      {
        method: 'DELETE',
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve();
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, `Error deleting transformer ${policyID}`));
      });
  });
};

export const fetchTenantUserPolicyPermissions = (
  tenantID: string,
  policyID: string = ''
): Promise<PermissionsOnObject> => {
  return new Promise(async (resolve, reject) => {
    const url = makeCompanyConfigURL(
      '/api/tenants/' +
        tenantID +
        '/policies/permissions' +
        (policyID ? '/' + policyID : '')
    );
    fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await extractErrorMessage(response);
          throw new HTTPError(message, response.status);
        }
        resolve(response.json());
      }, reject)
      .catch((e) => {
        reject(makeAPIError(e, 'error fetching user policy permissions'));
      });
  });
};
