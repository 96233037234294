import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  Button,
  Card,
  CardRow,
  Checkbox,
  EmptyState,
  IconFileList2,
  IconFilter,
  InlineNotification,
  Table,
  TableHead,
  TableRow,
  TableRowHead,
  TableBody,
  TableCell,
  Text,
  TextShortener,
  ToolTip,
} from '@userclouds/ui-component-lib';

import { AppDispatch, RootState } from '../store';
import { featureIsEnabled } from '../util/featureflags';
import { makeCleanPageLink } from '../AppNavigation';
import {
  bulkDeleteAccessPolicies,
  deleteSingleAccessPolicy,
  fetchAccessPolicies,
  fetchGlobalAccessorPolicy,
  fetchGlobalMutatorPolicy,
  fetchUserPolicyPermissions,
} from '../thunks/tokenizer';
import {
  toggleAccessPolicyForDelete,
  changeAccessPolicySearchFilter,
  toggleAccessPolicyListIncludeAutogenerated,
} from '../actions/tokenizer';
import { FeatureFlags } from '../models/FeatureFlag';
import PaginatedResult from '../models/PaginatedResult';
import AccessPolicy, {
  GLOBAL_ACCESSOR_POLICY_ID,
  GLOBAL_MUTATOR_POLICY_ID,
  ACCESS_POLICIES_COLUMNS,
  ACCESS_POLICIES_PREFIX,
} from '../models/AccessPolicy';
import { Filter } from '../models/authz/SearchFilters';
import PermissionsOnObject from '../models/authz/Permissions';

import Link from '../controls/Link';
import Pagination from '../controls/Pagination';
import Search from '../controls/Search';
import DeleteWithConfirmationButton from '../controls/DeleteWithConfirmationButton';

import PageCommon from './PageCommon.module.css';
import styles from './AccessPoliciesPage.module.css';

type AccessPoliciesProps = {
  selectedTenantID: string | undefined;
  policies: PaginatedResult<AccessPolicy> | undefined;
  globalAccessorPolicy: AccessPolicy | undefined;
  globalMutatorPolicy: AccessPolicy | undefined;
  fetchError: string | undefined;
  isFetching: boolean;
  includeAutogenerated: boolean;
  deleteQueue: string[];
  deleteSuccess: string;
  deleteErrors: string[];
  permissions: PermissionsOnObject;
  policySearchFilter: Filter;
  query: URLSearchParams;
  dispatch: AppDispatch;
};
const AccessPolicyList = ({
  selectedTenantID,
  policies,
  globalAccessorPolicy,
  globalMutatorPolicy,
  fetchError,
  isFetching,
  includeAutogenerated,
  deleteQueue,
  deleteSuccess,
  deleteErrors,
  permissions,
  policySearchFilter,
  query,
  dispatch,
}: AccessPoliciesProps) => {
  const cleanQuery = makeCleanPageLink(query);

  const deletePrompt = `Are you sure you want to delete ${
    deleteQueue.length
  } access policy${deleteQueue.length === 1 ? '' : 's'}? This action is irreversible.`;

  return (
    <>
      <div className={PageCommon.listviewtablecontrols}>
        <div>
          <IconFilter />
        </div>
        <Search
          columns={ACCESS_POLICIES_COLUMNS}
          changeSearchFilter={(filter: Filter) => {
            dispatch(changeAccessPolicySearchFilter(filter));
          }}
          prefix={ACCESS_POLICIES_PREFIX}
          searchFilter={policySearchFilter}
        />
        <Checkbox
          id="include_autogenerated"
          checked={includeAutogenerated}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              toggleAccessPolicyListIncludeAutogenerated(
                e.currentTarget.checked
              )
            );
          }}
        >
          Include autogenerated
        </Checkbox>

        <div className={PageCommon.listviewtablecontrolsToolTip}>
          <ToolTip>
            <>
              {'Manage your access policies. '}
              <a
                href="https://docs.userclouds.com/docs/how-it-works"
                title="UserClouds documentation for tokenizer"
                target="new"
                className={PageCommon.link}
              >
                Learn more here.
              </a>
            </>
          </ToolTip>
        </div>
        {permissions.create && (
          <Button
            theme="primary"
            size="small"
            className={PageCommon.listviewtablecontrolsButton}
          >
            <Link
              href={`/accesspolicies/create${cleanQuery}`}
              title="Add a new policy"
              applyStyles={false}
            >
              Create Policy
            </Link>
          </Button>
        )}
      </div>

      <Card listview>
        {policies ? (
          policies.data && policies.data.length ? (
            <>
              {!!deleteErrors.length && (
                <InlineNotification theme="alert" elementName="div">
                  <ul>
                    {deleteErrors.map((error: string) => (
                      <li key={`access_policies_error_${error}`}>{error}</li>
                    ))}
                  </ul>
                </InlineNotification>
              )}
              {deleteSuccess && (
                <InlineNotification theme="success">
                  {deleteSuccess}
                </InlineNotification>
              )}
              <div className={PageCommon.listviewpaginationcontrols}>
                <div className={PageCommon.listviewpaginationcontrolsdelete}>
                  <DeleteWithConfirmationButton
                    id="deleteAccessPoliciesButton"
                    message={deletePrompt}
                    onConfirmDelete={() => {
                      if (selectedTenantID) {
                        dispatch(
                          bulkDeleteAccessPolicies(
                            selectedTenantID,
                            deleteQueue
                          )
                        );
                      }
                    }}
                    title="Delete Access Policies"
                    disabled={deleteQueue.length < 1}
                  />
                </div>
                <Pagination
                  prev={policies?.prev}
                  next={policies?.next}
                  isLoading={isFetching}
                  prefix={ACCESS_POLICIES_PREFIX}
                />
              </div>

              <CardRow>
                <Table
                  spacing="packed"
                  id="accessPolicies"
                  className={styles.accesspoliciestable}
                >
                  <TableHead floating>
                    <TableRow>
                      <TableRowHead>
                        <Checkbox
                          checked={
                            Object.keys(deleteQueue).length ===
                            policies.data.length
                          }
                          onChange={() => {
                            const shouldMarkForDelete = !deleteQueue.includes(
                              policies.data[0].id
                            );
                            policies.data.forEach((o) => {
                              if (
                                shouldMarkForDelete &&
                                !deleteQueue.includes(o.id)
                              ) {
                                dispatch(toggleAccessPolicyForDelete(o.id));
                              } else if (
                                !shouldMarkForDelete &&
                                deleteQueue.includes(o.id)
                              ) {
                                dispatch(toggleAccessPolicyForDelete(o.id));
                              }
                            });
                          }}
                        />
                      </TableRowHead>
                      <TableRowHead>Name</TableRowHead>
                      <TableRowHead>Version</TableRowHead>
                      <TableRowHead>ID</TableRowHead>
                      <TableRowHead key="purpose_delete" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {globalAccessorPolicy && (
                      <TableRow
                        key={
                          globalAccessorPolicy.id +
                          '-' +
                          globalAccessorPolicy.version
                        }
                        className={clsx(
                          deleteQueue.includes(globalAccessorPolicy.id)
                            ? PageCommon.queuedfordelete
                            : '',
                          PageCommon.listviewtablerow,
                          'global-policy'
                        )}
                      >
                        <TableCell>
                          <Checkbox
                            id={'delete' + globalAccessorPolicy.id}
                            name="delete policy"
                            disabled
                            checked={false}
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            href={`/accesspolicies/${
                              globalAccessorPolicy.id
                            }/${globalAccessorPolicy.version.toString()}${cleanQuery}`}
                          >
                            <Text>
                              {globalAccessorPolicy.name || '[Unnamed policy]'}
                            </Text>
                          </Link>
                        </TableCell>
                        <TableCell>{globalAccessorPolicy.version}</TableCell>
                        <TableCell>
                          <TextShortener
                            text={globalAccessorPolicy.id}
                            length={6}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          className={PageCommon.listviewtabledeletecell}
                        />
                      </TableRow>
                    )}
                    {globalMutatorPolicy && (
                      <TableRow
                        key={
                          globalMutatorPolicy.id +
                          '-' +
                          globalMutatorPolicy.version
                        }
                        className={clsx(
                          deleteQueue.includes(globalMutatorPolicy.id)
                            ? PageCommon.queuedfordelete
                            : '',
                          PageCommon.listviewtablerow,
                          'global-policy'
                        )}
                      >
                        <TableCell>
                          <Checkbox
                            id={'delete' + globalMutatorPolicy.id}
                            name="delete_policy"
                            disabled
                            checked={false}
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            href={`/accesspolicies/${
                              globalMutatorPolicy.id
                            }/${globalMutatorPolicy.version.toString()}${cleanQuery}`}
                          >
                            <Text>
                              {globalMutatorPolicy.name || '[Unnamed policy]'}
                            </Text>
                          </Link>
                        </TableCell>
                        <TableCell>{globalMutatorPolicy.version}</TableCell>
                        <TableCell>
                          <TextShortener
                            text={globalMutatorPolicy.id}
                            length={6}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          className={PageCommon.listviewtabledeletecell}
                        />
                      </TableRow>
                    )}
                    {policies.data
                      .filter(
                        (entry) =>
                          !globalAccessorPolicy ||
                          entry.id !== GLOBAL_ACCESSOR_POLICY_ID
                      )
                      .filter(
                        (entry) =>
                          !globalMutatorPolicy ||
                          entry.id !== GLOBAL_MUTATOR_POLICY_ID
                      )
                      .map((entry) => (
                        <TableRow
                          key={entry.id + '-' + entry.version}
                          className={
                            (deleteQueue.includes(entry.id)
                              ? PageCommon.queuedfordelete
                              : '') +
                            ' ' +
                            PageCommon.listviewtablerow
                          }
                        >
                          <TableCell>
                            <Checkbox
                              id={'delete' + entry.id}
                              name="delete_policy"
                              checked={deleteQueue.includes(entry.id)}
                              onChange={() => {
                                dispatch(toggleAccessPolicyForDelete(entry.id));
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Link
                              href={`/accesspolicies/${
                                entry.id
                              }/${entry.version.toString()}${cleanQuery}`}
                            >
                              <Text>{entry.name || '[Unnamed policy]'}</Text>
                            </Link>
                          </TableCell>
                          <TableCell>{entry.version}</TableCell>
                          <TableCell>
                            <TextShortener text={entry.id} length={6} />
                          </TableCell>
                          <TableCell
                            align="right"
                            className={PageCommon.listviewtabledeletecell}
                          >
                            <DeleteWithConfirmationButton
                              id="deleteMutatorButton"
                              message="Are you sure you want to delete this access policy? This action is irreversible."
                              onConfirmDelete={() => {
                                if (selectedTenantID) {
                                  dispatch(
                                    deleteSingleAccessPolicy(
                                      selectedTenantID,
                                      entry
                                    )
                                  );
                                }
                              }}
                              title="Delete Access Policy"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardRow>
            </>
          ) : (
            <CardRow>
              <EmptyState
                title="No access policies"
                image={<IconFileList2 size="large" />}
              >
                <Button theme="secondary">
                  <Link
                    href={`/accesspolicies/create${cleanQuery}`}
                    applyStyles={false}
                  >
                    + Add access policy
                  </Link>
                </Button>
              </EmptyState>
            </CardRow>
          )
        ) : isFetching ? (
          <Text>Loading policies...</Text>
        ) : (
          <InlineNotification theme="alert">
            {fetchError || 'Something went wrong'}
          </InlineNotification>
        )}
      </Card>
    </>
  );
};
const ConnectedAccessPolicyList = connect((state: RootState) => {
  return {
    selectedTenantID: state.selectedTenantID,
    policies: state.accessPolicies,
    globalAccessorPolicy: state.globalAccessorPolicy,
    globalMutatorPolicy: state.globalMutatorPolicy,
    fetchError: state.accessPolicyFetchError,
    isFetching: state.fetchingAccessPolicies,
    includeAutogenerated: state.accessPoliciesIncludeAutogenerated,
    deleteQueue: state.accessPoliciesDeleteQueue,
    deleteSuccess: state.deleteAccessPoliciesSuccess,
    deleteErrors: state.deleteAccessPoliciesErrors,
    policySearchFilter: state.accessPolicySearchFilter,
    query: state.query,
  };
})(AccessPolicyList);

const AccessPoliciesPage = ({
  selectedTenantID,
  userPolicyPermissions,
  permissionsFetchError,
  featureFlags,
  includeAutogenerated,
  query,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  userPolicyPermissions: PermissionsOnObject | undefined;
  permissionsFetchError: string;
  featureFlags: FeatureFlags | undefined;
  includeAutogenerated: boolean;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  const globalPolicies = featureIsEnabled(
    'global-access-policies',
    featureFlags
  );
  useEffect(() => {
    if (selectedTenantID) {
      dispatch(fetchUserPolicyPermissions(selectedTenantID));
    }
  }, [dispatch, selectedTenantID]);

  useEffect(() => {
    if (selectedTenantID && userPolicyPermissions?.read) {
      dispatch(
        fetchAccessPolicies(selectedTenantID, query, includeAutogenerated)
      );
    }
  }, [
    userPolicyPermissions,
    selectedTenantID,
    includeAutogenerated,
    query,
    dispatch,
  ]);
  useEffect(() => {
    if (
      selectedTenantID &&
      userPolicyPermissions?.read &&
      globalPolicies &&
      !query.get(`${ACCESS_POLICIES_PREFIX}filter`)
    ) {
      dispatch(fetchGlobalAccessorPolicy(selectedTenantID));
      dispatch(fetchGlobalMutatorPolicy(selectedTenantID));
    }
  }, [
    globalPolicies,
    userPolicyPermissions,
    selectedTenantID,
    query,
    dispatch,
  ]);

  return (
    <>
      {userPolicyPermissions?.read ? (
        <ConnectedAccessPolicyList permissions={userPolicyPermissions} />
      ) : userPolicyPermissions ? (
        <Card
          title="Request access"
          description="You do not have permission to view any policies. Please contact your administrator to request access."
        >
          {permissionsFetchError && (
            <InlineNotification theme="alert">
              {permissionsFetchError}
            </InlineNotification>
          )}
        </Card>
      ) : (
        <Card
          title="Loading..."
          description="Loading policies and permissions."
        />
      )}
    </>
  );
};

export default connect((state: RootState) => {
  return {
    selectedTenantID: state.selectedTenantID,
    userPolicyPermissions: state.userPolicyPermissions,
    permissionsFetchError: state.userPolicyPermissionsFetchError,
    templatesEditMode: state.policyTemplateEditMode,
    transformerEditMode: state.transformerEditMode,
    featureFlags: state.featureFlags,
    location: state.location,
    includeAutogenerated: state.accessPoliciesIncludeAutogenerated,
    query: state.query,
  };
})(AccessPoliciesPage);
