import { IconTypes, getSize } from '../iconHelpers';

function IconManageTeam({
  className,
  size = 'medium',
}: IconTypes): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={getSize(size)}
      height={getSize(size)}
      className={className}
      fill="currentColor"
    >
      <title>Manage Team</title>
      <path
        d="M9.99984 9.16675C11.1049 9.16675 12.1647 9.60573 12.9461 10.3871C13.7275 11.1685 14.1665 12.2283 14.1665 13.3334V18.3334H5.83317V13.3334C5.83317 12.2283 6.27216 11.1685 7.05356 10.3871C7.83496 9.60573 8.89477 9.16675 9.99984 9.16675ZM4.4065 11.6717C4.27389 12.1191 4.19554 12.5807 4.17317 13.0467L4.1665 13.3334V18.3334H1.6665V14.5834C1.66634 13.8647 1.93153 13.1713 2.4112 12.6361C2.89087 12.1009 3.55125 11.7616 4.26567 11.6834L4.40734 11.6717H4.4065ZM15.5932 11.6717C16.3348 11.7169 17.0312 12.0434 17.5403 12.5844C18.0495 13.1255 18.3331 13.8404 18.3332 14.5834V18.3334H15.8332V13.3334C15.8332 12.7559 15.7498 12.1984 15.5932 11.6717ZM4.58317 6.66675C5.1357 6.66675 5.66561 6.88624 6.05631 7.27694C6.44701 7.66764 6.6665 8.19755 6.6665 8.75008C6.6665 9.30262 6.44701 9.83252 6.05631 10.2232C5.66561 10.6139 5.1357 10.8334 4.58317 10.8334C4.03064 10.8334 3.50073 10.6139 3.11003 10.2232C2.71933 9.83252 2.49984 9.30262 2.49984 8.75008C2.49984 8.19755 2.71933 7.66764 3.11003 7.27694C3.50073 6.88624 4.03064 6.66675 4.58317 6.66675ZM15.4165 6.66675C15.969 6.66675 16.4989 6.88624 16.8896 7.27694C17.2803 7.66764 17.4998 8.19755 17.4998 8.75008C17.4998 9.30262 17.2803 9.83252 16.8896 10.2232C16.4989 10.6139 15.969 10.8334 15.4165 10.8334C14.864 10.8334 14.3341 10.6139 13.9434 10.2232C13.5527 9.83252 13.3332 9.30262 13.3332 8.75008C13.3332 8.19755 13.5527 7.66764 13.9434 7.27694C14.3341 6.88624 14.864 6.66675 15.4165 6.66675ZM9.99984 1.66675C10.8839 1.66675 11.7317 2.01794 12.3569 2.64306C12.982 3.26818 13.3332 4.11603 13.3332 5.00008C13.3332 5.88414 12.982 6.73198 12.3569 7.3571C11.7317 7.98223 10.8839 8.33341 9.99984 8.33341C9.11578 8.33341 8.26794 7.98223 7.64281 7.3571C7.01769 6.73198 6.6665 5.88414 6.6665 5.00008C6.6665 4.11603 7.01769 3.26818 7.64281 2.64306C8.26794 2.01794 9.11578 1.66675 9.99984 1.66675Z"
        fill="#283354"
      />{' '}
    </svg>
  );
}

export default IconManageTeam;
