const actions = {
  // Service Info
  GET_SERVICE_INFO_REQUEST: 'GET_SERVICE_INFO_REQUEST',
  GET_SERVICE_INFO_SUCCESS: 'GET_SERVICE_INFO_SUCCESS',
  GET_SERVICE_INFO_ERROR: 'GET_SERVICE_INFO_ERROR',

  // My Profile
  GET_MY_PROFILE_REQUEST: 'GET_MY_PROFILE_REQUEST',
  GET_MY_PROFILE_SUCCESS: 'GET_MY_PROFILE_SUCCESS',

  // Companies
  GET_ALL_COMPANIES_REQUEST: 'GET_ALL_COMPANIES_REQUEST',
  GET_ALL_COMPANIES_SUCCESS: 'GET_ALL_COMPANIES_SUCCESS',
  GET_ALL_COMPANIES_ERROR: 'GET_ALL_COMPANIES_ERROR',
  CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_ERROR: 'CREATE_COMPANY_ERROR',
  CHANGE_SELECTED_COMPANY: 'CHANGE_SELECTED_COMPANY',
  GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_ERROR: 'GET_COMPANIES_FAILURE',
  TOGGLE_CREATE_COMPANY_DIALOG: 'TOGGLE_CREATE_COMPANY_DIALOG',
  TOGGLE_CHANGE_COMPANY_DIALOG: 'TOGGLE_CHANGE_COMPANY_DIALOG',
  toggleCreateCompanyDialog: (isOpen?: boolean) => ({
    type: actions.TOGGLE_CREATE_COMPANY_DIALOG,
    data: isOpen,
  }),
  toggleChangeCompanyDialog: (isOpen?: boolean) => ({
    type: actions.TOGGLE_CHANGE_COMPANY_DIALOG,
    data: isOpen,
  }),

  // End Users
  GET_TENANT_ORGS_REQUEST: 'GET_TENANT_ORGS_REQUEST',
  GET_TENANT_ORGS_SUCCESS: 'GET_TENANT_ORGS_SUCCESS',
  GET_TENANT_ORGS_ERROR: 'GET_TENANT_ORGS_ERROR',
  SELECT_USERS_TENANT_ORGANIZATION: 'SELECT_USERS_TENANT_ORGANIZATION',
  GET_TENANT_USERS_REQUEST: 'GET_TENANT_USERS_REQUEST',
  GET_TENANT_USERS_SUCCESS: 'GET_TENANT_USERS_SUCCESS',
  GET_TENANT_USERS_ERROR: 'GET_TENANT_USERS_ERROR',
  TOGGLE_USER_FOR_DELETE: 'TOGGLE_USER_FOR_DELETE',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  BULK_UPDATE_USERS_START: 'BULK_UPDATE_USERS_START',
  BULK_UPDATE_USERS_END: 'BULK_UPDATE_USERS_END',

  // Team (customer employees)
  GET_COMPANY_INVITES_REQUEST: 'GET_COMPANY_INVITES_REQUEST',
  GET_COMPANY_INVITES_SUCCESS: 'GET_COMPANY_INVITES_SUCCESS',
  GET_COMPANY_INVITES_ERROR: 'GET_COMPANY_INVITES_ERROR',
  GET_INVITES_PREV_PAGE: 'GET_INVITES_PREV_PAGE',
  GET_INVITES_NEXT_PAGE: 'GET_INVITES_NEXT_PAGE',
  GET_USERROLES_REQUEST: 'GET_USERROLES_REQUEST',
  GET_COMPANY_USERROLES_REQUEST: 'GET_COMPANY_USERROLES_REQUEST',
  GET_USERROLES_SUCCESS: 'GET_USERROLES_SUCCESS',
  GET_COMPANY_USERROLES_SUCCESS: 'GET_COMPANY_USERROLES_SUCCESS',
  GET_USERROLES_ERROR: 'GET_USERROLES_ERROR',
  GET_COMPANY_USERROLES_ERROR: 'GET_COMPANY_USERROLES_ERROR',
  DELETE_COMPANY_USERROLES_REQUEST: 'DELETE_COMPANY_USERROLES_REQUEST',
  DELETE_COMPANY_USERROLES_SUCCESS: 'DELETE_COMPANY_USERROLES_SUCCESS',
  DELETE_COMPANY_USERROLES_ERROR: 'DELETE_COMPANY_USERROLES_ERROR',
  UPDATE_USERROLES_REQUEST: 'UPDATE_USERROLES_REQUEST',
  UPDATE_COMPANY_USERROLES_REQUEST: 'UPDATE_COMPANY_USERROLES_REQUEST',
  UPDATE_USERROLES_SUCCESS: 'UPDATE_USERROLES_SUCCESS',
  UPDATE_COMPANY_USERROLES_SUCCESS: 'UPDATE_COMPANY_USERROLES_SUCCESS',
  UPDATE_USERROLES_ERROR: 'UPDATE_USERROLES_ERROR',
  UPDATE_COMPANY_USERROLES_ERROR: 'UPDATE_COMPANY_USERROLES_ERROR',
  BULK_UPDATE_USERROLES_START: 'BULK_UPDATE_USERROLES_START',
  BULK_UPDATE_COMPANY_USERROLES_START: 'BULK_UPDATE_COMPANY_USERROLES_START',
  BULK_UPDATE_USERROLES_END: 'BULK_UPDATE_USERROLES_END',
  BULK_UPDATE_COMPANY_USERROLES_END: 'BULK_UPDATE_COMPANY_USERROLES_END',
  MODIFY_USER_ROLE: 'MODIFY_USER_ROLE',
  MODIFY_COMPANY_USER_ROLE: 'MODIFY_COMPANY_USER_ROLE',
  TOGGLE_USERROLES_FOR_DELETE: 'TOGGLE_USERROLES_FOR_DELETE',
  TOGGLE_COMPANY_USERROLES_FOR_DELETE: 'TOGGLE_COMPANY_USERROLES_FOR_DELETE',
  TOGGLE_USERROLES_EDIT_MODE: 'TOGGLE_USERROLES_EDIT_MODE',
  TOGGLE_COMPANY_USERROLES_EDIT_MODE: 'TOGGLE_COMPANY_USERROLES_EDIT_MODE',

  // Status page
  GET_ACTIVE_INSTANCES_REQUEST: 'GET_ACTIVE_INSTANCES_REQUEST',
  GET_ACTIVE_INSTANCES_SUCCESS: 'GET_ACTIVE_INSTANCES_SUCCESS',
  GET_ACTIVE_INSTANCES_ERROR: 'GET_ACTIVE_INSTANCES_ERROR',
  GET_EVENT_LOG_REQUEST: 'GET_EVENT_LOG_REQUEST',
  GET_EVENT_LOG_SUCCESS: 'GET_EVENT_LOG_SUCCESS',
  GET_EVENT_LOG_ERROR: 'GET_EVENT_LOG_ERROR',
  GET_CHART_DATA_REQUEST: 'GET_CHART_DATA_REQUEST',
  GET_CHART_DATA_SUCCESS: 'GET_CHART_DATA_SUCCESS',
  GET_CHART_DATA_ERROR: 'GET_CHART_DATA_ERROR',
  CHANGE_CHART_DATA_SERVICE: 'CHANGE_CHART_DATA_SERVICE',
  CHANGE_CHART_DATA_TIME_PERIOD: 'CHANGE_CHART_DATA_TIME_PERIOD',
};
export default actions;
