import { v4 as uuidv4 } from 'uuid';

export const GLOBAL_ACCESSOR_POLICY_ID = 'a78f1f88-3684-4e59-a01d-c121e259ec96';
export const GLOBAL_MUTATOR_POLICY_ID = '804e84f1-7fa4-4bb4-b785-4c89e1ceaba0';

export const blankPolicyTemplate = () => ({
  id: uuidv4(),
  name: '',
  description: '',
  function: 'function policy(context, params) {\n  return true;\n}',
  is_system: false,
  version: 0,
});

export type AccessPolicyTemplate = {
  id: string;
  name: string;
  description: string;
  function: string;
  is_system?: boolean;
  version: number;
};
export const ACCESS_POLICY_TEMPLATE_COLUMNS = [
  'id',
  'name',
  'description',
  'created',
  'updated',
];

export type ComponentPolicy = {
  isPolicy: boolean;
  policy: AccessPolicy;
  template: AccessPolicyTemplate;
  template_parameters: string;
};

export type TemplateForComponent = {
  template: AccessPolicyTemplate;
  template_parameters: string;
};

export type AccessPolicyComponent =
  | TemplateForComponent
  | { policy: { name: string; id: string } };

export enum AccessPolicyType {
  AND = 'composite_and',
  OR = 'composite_or',
}

export const isTemplate = (apComponent: AccessPolicyComponent): boolean => {
  if ('policy' in apComponent) {
    return false;
  }
  if ('template' in apComponent) {
    return true;
  }
  return false;
};

export const getNameForAccessPolicyComponent = (
  apComponent: AccessPolicyComponent
) => {
  if ('policy' in apComponent) {
    return apComponent.policy.name;
  }
  if ('template' in apComponent) {
    return apComponent.template.name;
  }
  return false;
};

export const getIDForAccessPolicyComponent = (
  apComponent: AccessPolicyComponent
) => {
  if ('policy' in apComponent) {
    return apComponent.policy.id;
  }
  if ('template' in apComponent) {
    return apComponent.template.id;
  }
  return '';
};

export const getTypeForAccessPolicyComponent = (
  apComponent: AccessPolicyComponent
) => {
  if ('policy' in apComponent) {
    return 'Policy';
  }
  if ('template' in apComponent) {
    return 'Template';
  }
  return 'Unknown';
};

export const getNewComponentsOnNameChange = (
  name: string,
  componentPolicies: ComponentPolicy[],
  policy: AccessPolicy,
  i: number
) => {
  const templatesOnly = name.includes(' - (template)');
  const policiesOnly = name.includes(' - (policy)');
  name = name.split(' ')[0];
  const componentPolicy = componentPolicies.find((comp) => {
    if (name) {
      if (comp.isPolicy && !templatesOnly) {
        return name === comp.policy.name;
      }
      if (!comp.isPolicy && !policiesOnly) {
        return name === comp.template.name;
      }
    }
    return false;
  });

  if (!componentPolicy) {
    return policy.components;
  }
  let newVal: AccessPolicyComponent;
  if (componentPolicy.isPolicy) {
    newVal = {
      policy: {
        name: componentPolicy.policy.name,
        id: componentPolicy.policy.id,
      },
    };
  } else {
    newVal = {
      template: componentPolicy.template,
      template_parameters: componentPolicy.template_parameters,
    };
  }
  const newComponents = [...policy.components];
  newComponents[i] = newVal;
  return newComponents;
};

export const blankPolicyComponent = () => ({
  isPolicy: false,
  policy: blankPolicy(),
  template: blankPolicyTemplate(),
  template_parameters: '',
});

export const blankPolicy = () => ({
  id: uuidv4(),
  name: '',
  policy_type: AccessPolicyType.AND,
  tag_ids: [],
  components: [],
  version: 0,
  is_system: false,
  is_autogenerated: false,
  required_context: {},
  required_context_stringified: '{}',
  thresholds: blankAccessPolicyThresholds(),
});

type AccessPolicyThresholds = {
  announce_max_execution_failure: boolean;
  announce_max_result_failure: boolean;
  max_executions: number;
  max_execution_duration_seconds: number;
  max_results_per_execution: number;
};

export const blankAccessPolicyThresholds = () => ({
  announce_max_execution_failure: false,
  announce_max_result_failure: false,
  max_executions: 0,
  max_execution_duration_seconds: 0,
  max_results_per_execution: 0,
});

export const stringifyContext = (policy: AccessPolicy) =>
  policy.required_context ? JSON.stringify(policy.required_context) : '{}';

type AccessPolicy = {
  id: string; // TODO: UUID
  name: string;
  policy_type: AccessPolicyType;
  tag_ids: string[];
  components: AccessPolicyComponent[];
  version: number;
  required_context: Record<string, string>;
  required_context_stringified?: string;
  is_system?: boolean;
  is_autogenerated?: boolean;
  thresholds: AccessPolicyThresholds;
};

export type AccessPolicyTestResult = {
  allowed: boolean;
  debug: Record<string, any>;
};

export const ACCESS_POLICIES_COLUMNS = ['id', 'name', 'created', 'updated'];

export const ACCESS_POLICIES_PREFIX = 'access_policies_';
export const ACCESS_POLICY_TEMPLATE_PREFIX = 'templates_';

export enum PolicySelectorResourceType {
  TEMPLATE = 'template',
  POLICY = 'policy',
}

export default AccessPolicy;
